import React, { useState, useEffect } from 'react';
import loginlogo from '../images/login-logo.png';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import tshirt from '../images/tshirt.jpeg';
import tshirtnavy from '../images/tshirtnavy.jpeg';
import axios from 'axios';
import back from '../images/backarrow.png';
import { Link, Redirect } from 'react-router-dom';
import { config } from '../Config/Config';
import BottomMenu from "../BottomMenu/BottomMenu";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function ViewTshirtOrder(props) {
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [size, setSize] = useState('M');
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState();
    const [totalCost,setTotalCost]=useState()
    const [address, setAddress] = useState('');
    const [imageOnTshirt, setImageOnTshirt] = useState('');
    const [color, setColor] = useState('White');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [picture, setPicture] = useState('')
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [orderId, setOrderId] = useState('');

    useEffect(() => {
        getTshirtOrderByOrderId();
        console.log("kk", props.match.params.id)
    }, [])
    const getTshirtOrderByOrderId = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        };

        var payload = {
            "orderId": props.match.params.id
        }

        axios.post(config.userUrl + `user/getOrderByOrderId`, payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setOrderId(response.data.order[0]?.orderId);
                    setImageOnTshirt(response.data.order[0]?.imageOnTshirt);
                    setSize(response.data.order[0]?.size);
                    setPrice(response.data.order[0]?.price);
                    setTotalCost(response.data.order[0]?.totalCost)
                    setQuantity(response.data.order[0]?.quantity);
                    setColor(response.data.order[0]?.color);
                    setAddress(response.data.order[0]?.address);
                    setName(response.data.order[0]?.name);
                    setEmail(response.data.order[0]?.email);
                    setPhoneNumber(response.data.order[0]?.phoneNumber);

                } else {
                    console.log(response.data);
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    return (
        <div>
            <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
            <div style={{ marginTop: "2.5rem" }}>
                <div className="your-jitbits"><Link to={{ pathname: `/purchases` }} className="mr-1 link"><img alt="" src={back} style={{ width: "30px" }} /></Link>View Order Details</div>
                <Form className="ml-3 mr-3" style={{ fontFamily: "Noto Sans", marginTop: "-5px" }}>
                    <div style={{ border: "1px solid gray", borderRadius: "15px", padding: "5px" }}>
                        <Row style={{ width: "100%" }} className='no-gutters'>
                            <Col xs={5}>
                                <center>
                                    <div style={{ position: 'relative', display: 'inline-block' }}>
                                        <img src={color === "White" ? tshirt : tshirtnavy} style={{ width: "100%" }} alt="T-shirt" />
                                        <img
                                            src={config.userUrl + imageOnTshirt}
                                            style={color === "White" ? {
                                                position: 'absolute',
                                                top: '39%',
                                                left: '38%',
                                                width: '25%',
                                                height: 'auto',
                                            } : {
                                                position: 'absolute',
                                                top: '39%',
                                                left: '38%',
                                                width: '25%',
                                                height: 'auto',
                                                filter: 'invert(1)',
                                            }}
                                            alt="Overlay"
                                        />
                                        <span className="label-order-text">Rs.{price}</span>
                                    </div>
                                </center>
                            </Col>
                            <Col xs={7}>
                                <Row style={{ width: "100%" }} className='no-gutters'>
                                    <Col>

                                        <label className="label-order-text">Color</label>
                                        <input type="text" className='form-control form-fontsize' readOnly value={color} />
                                    </Col>
                                    <Col>
                                        <label className="label-order-text">Size</label>
                                        <input type="text" className='form-control form-fontsize' readOnly value={size} />
                                    </Col>
                                </Row>
                                <Row style={{ width: "100%" }} className='no-gutters'>
                                    <Col>
                                        <label className="label-order-text">Quantity</label>
                                        <input type="text" className='form-control form-fontsize' readOnly value={quantity} />

                                    </Col>
                                    <Col>
                                        <label className="label-order-text" for="price">Total Cost</label>
                                        <input type="text" className='form-control form-fontsize' readOnly value={` Rs.${totalCost}`} />

                                        <div style={{ fontSize: "9px" }}>Incl. Rs.150, Shipping
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <div style={{ border: "1px solid gray", borderRadius: "15px", padding: "5px" }}>
                        <label className="label-order-text">Name</label>
                        <input
                            className='form-control label-order-text'
                            type="text"
                            placeholder="Name"
                            value={name}
                            readOnly
                        />
                        <label className="label-order-text">Address</label>
                        <textarea
                            className='form-control label-order-text'
                            rows={3}
                            style={{ resize: "none", width: "100%", padding: "10px", borderRadius: "4px" }}
                            placeholder="Enter your address"
                            value={address}
                            readOnly
                        />

                        <label className="label-order-text">Email</label>
                        <input
                            className='form-control label-order-text'
                            type="email"
                            placeholder="Enter email"
                            value={email}
                            readOnly
                        />
                        <label className="label-order-text">Phone Number</label>
                        <input
                            className='form-control label-order-text'
                            type="text"
                            placeholder="Enter your phone number"
                            value={phoneNumber}
                            readOnly
                        />
                    </div>
                    <center className='mt-3'><Link to={`/trackinglink/${orderId}`}><Button variant="default" className="mt-1 track-btn">Track Details</Button></Link></center>
                    {/* {address !== "" ?
                            <Button variant="default" className="mt-1 payment-submit-button btn-block" onClick={handleSubmit}>Submit</Button>
                            :
                            <Button variant="default" disabled="true" className="mt-1 payment-submit-button btn-block" onClick={handleSubmit}>Submit</Button>
                        } */}
                </Form>
            </div>
            <BottomMenu />
        </div >
    )
}
