
import React, { useState, useEffect } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { Link,NavLink } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import moment from "moment-timezone";
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import { config } from '../Config/Config';
import '../JitBits/JitBits.css';
import back from '../images/backarrow.png';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


export default function RewardsHistory() {
    const [claimHistory, setClaimHistory] = useState([])
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        getClaimHistoryForUser();
    }, []);

    const getClaimHistoryForUser = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getClaimHistoryForUser', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setClaimHistory(response.data.claimHistory)
                    setIsLoading(false)
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    // if (isLoading) {
    //     console.debug("renders: Loading...");
    //     return <div className="App"></div>;
    // }
    return (

        <div >
            <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
            <div className="leaderboard-topgap">
            <div><Link to="/rewardpointshistory"><img alt="" src={back} className="claims-back_arrow" /></Link><span style={{marginLeft:"18%",fontFamily:"Noto Sans",fontSize:"16px",fontWeight:600,color:"#ff942c"}}>Redemption History</span></div>
               
                {/* <div className="recommended-fixed">
                    <div style={{ marginTop: "13px", display: "flex", marginLeft: "15px" }}>
                        <NavLink to="/history/leaderboard" activeClassName="profile-active" className="ml-2 link" >
                            <div className="followers-Text ml-1 mr-1">Leaderboard </div></NavLink>
                        <NavLink to="/history/rewardshistory" activeClassName="profile-active" className="rec-left link" >
                            <div className="followers-Text ml-1 mr-1">Claims History</div></NavLink>

                    </div>
                </div>
                <div style={{ marginTop: "6.2rem" }}>
                </div> */}
                <div className="ml-4 mr-4">
                    <div>
                        {claimHistory.length === 0 ? <center className="mt-5"><h4>No History.</h4></center> :
                            <div class="mt-3 tableFixed" >
                                 <table class="table-striped" >
                                    <thead style={{ background: "#eee" }}>
                                        <tr>
                                            <th scope="col" className="th-heading" style={{ width: "17%" }}>Purchases</th>
                                            <th scope="col" className="th-heading" style={{ width: "16%" }}>Redeemed</th>
                                            <th scope="col" className="th-heading" style={{ width: "17%" }}>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>{claimHistory.map((data, i) => (
                                        <tr style={{fontSize:"14px",fontFamily:"Noto Sans"}}className={i / 2 === 0 ? "" : "green"}>
                                            <td className="td-text">{data.relatedName}</td>
                                            <td className="td-text">{data.rewardPoints}</td>
                                            <td className="td-text">{moment(data.createdAt).format("DD-MM-YYYY")}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                </div>
                {/* <Link to="/rewardpointshistory"><div style={{position:"absolute",left:"0",right:"0",textAlign:"center",bottom:"4rem"}}className="_rewards">History</div></Link> */}
            </div>
            <div className="mb-bottom"></div>
            <BottomMenu />
        </div >
    )
}
