import React, { Component } from "react";
import { Button, Form, Col, Card, Row, Image } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import back from '../images/backarrow.png';
import axios from 'axios';
import { config } from '../Config/Config';
import BottomMenu from "../BottomMenu/BottomMenu";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class Purchases extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            transactions: [],
            orders: [],
            isLoading: true,
            transactionId: "",
            planName: "",
            planAmount: "",
            totalCost: "",
            price: "",
            currency: "",
            status: "",
            transactionImage: "",
            refTransactionImage: "",
            createdOn: "",
            id: "",
            planId: "",
            quantity: "",
            color: "",
            size: "",
            transactionStatus: "",
            paymentMethod: "",
            emailText: "",
            upi: false

        }
    }

    componentDidMount() {
        this.getUserTransactions();
        this.getUserOrders();
        //  console.log("state", this.state)
    }
    closeModal() {
        document.getElementById('sameplan').style.display = 'none';
    }
    openBox() {
        document.getElementById('sameplan').style.display = 'flex';
    }
    showNone() {
        document.getElementById('trans').style.display = 'none';
    }
    showTransaction() {
        document.getElementById('trans').style.display = 'flex';
    }
    closeOrderdetails() {
        document.getElementById('order').style.display = 'none';
    }
    openOrderDetails() {
        document.getElementById('order').style.display = 'flex';
    }
    getTransactionDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile",
            "responseType": "arraybuffer"
        }
        var payload = {
            "transactionId": this.state.transactionId
        }
        var self = this;
        self.closeModal()
        axios.post(config.userUrl + 'user/userTransactionsPdfDownload', payload, { headers: headers })
            .then(function (response) {
                //  console.log("downloaded", response)
                if (response.data.status === true) {
                    // console.log("downloaded")
                    self.showTransaction();
                    self.setState({
                        emailText: "Email sent successfully!! Please check your email."
                    })
                } else {
                    //console.log("error")
                    self.setState({
                        emailText: "Sorry! There is some issue. Please try again after some time"
                    })
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    getUserTransactions = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getUserTransactions', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status == true) {
                    self.setState({ transactions: response.data.getUserTransaction, isLoading: false })
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    getUserOrders = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAllTshirtOrdersByUserId', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status == true) {
                    self.setState({ orders: response.data.orders, isLoading: false })
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    showUpi = () => {
        this.setState({ upi: true })
    }
    show = () => {
        //console.log("open")
    }
    // &nbsp; <b>{data.invitationUserName}</b> ({data.email})<span style={{ float: "right", marginRight: "10px" }}> &nbsp;{data.invitationStatus == 2 ? <i class="fa fa-question fa-lg" style={{ color: "red" }} aria-hidden="true"></i> : (data.invitationStatus == 1 ? <i style={{ color: "green" }} class="fa fa-check fa-lg" aria-hidden="true"></i> : <i style={{ color: "red" }} class="fa fa-times fa-lg" aria-hidden="true"></i>)}</span>
    render() {
        const { isLoading, orders, upi, transactions, transactionStatus } = this.state;
        if (isLoading) {
            return <div className="App"></div>;
        }
        if (upi) {
            return <Redirect
                to={{
                    pathname: "/upidetails",
                    state: { id: this.state.id, planId: this.state.planId, planName: this.state.planName, amount: this.state.planAmount }
                }} />
        }
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo mt-2" /></div>
                <div className="screen-topgap">
                    <div className="your-jitbits">Transactions</div>
                    <div className="ml-3 mr-3 mt-3 top-jit">
                        {transactions.length === 0 ? <center><h4>No Transactions.</h4></center> :
                            <div className="tableFixHead">
                                <table class="table-striped" style={{ width: "100%" }}>
                                    <thead >
                                        <tr>
                                            <th scope="col" className="th-heading" style={{ width: "40%" }}>Date</th>
                                            <th scope="col" className="th-heading" style={{ width: "30%" }}>Amount (&#8377;)</th>
                                            <th scope="col" className="th-heading td-p-data_" style={{ width: "25%" }}>Status</th>
                                            <th scope="col" className="th-heading" style={{ width: "5%" }}>Details</th>
                                        </tr></thead>

                                    <tbody >
                                        {transactions.map((data, i) => (
                                            <tr style={{ width: "100%" }} onClick={() => this.setState({ id: data.id, transactionImage: data.transactionImage, refTransactionImage: data.refTransactionImage, transactionId: data.transactionId, planId: data.planId, planName: data.planName, planAmount: data.planAmount, status: data.status, createdOn: data.createdAt })}>
                                                <td style={{ width: "40%" }} className="td-p-data">{data.createdAt.split("T")[0].split("-").reverse().join("-")}</td>
                                                <td style={{ width: "30%" }} className="td-p-data">{data.planAmount}</td>
                                                <td style={{ width: "25%", fontSize: "12px" }} className="td-p-data_" onClick={data.status == 1 || data.status == null ? this.showUpi : this.show}>{data.status == 5 ? "Refunded" : (data.status == 2 ? "Completed" : (data.status == 3 ? "Accepted" : (data.status == 4 ? "Rejected" : "Waiting")))}</td>

                                                {/* <td style={{ width: "25%" }} className="td-p-data_" onClick={data.active == false ? this.showUpi : this.show}>{data.status == false ? <i style={{ color: "red" }} class="fa fa-times fa-lg" aria-hidden="true"></i> : <i style={{ color: "green" }} class="fa fa-check fa-lg" aria-hidden="true"></i>}</td> */}
                                                <td style={{ width: "5%" }} className="td-p-data_" onClick={this.openBox}><i class="fa fa-eye" aria-hidden="true"></i></td>
                                            </tr>
                                        ))}
                                        {orders.map((data, i) => (
                                            <tr style={{ width: "100%" }} onClick={() => this.setState({ id: data.id, planId: data.orderId, totalCost: data.totalCost, price: data.price, size: data.size, color: data.color, quantity: data.quantity, status: data.orderStatus, transactionStatus: data.transactionStatus, paymentMethod: data.paymentMethod, createdOn: data.createdAt })}>
                                                <td style={{ width: "40%" }} className="td-p-data">{data.createdAt.split("T")[0].split("-").reverse().join("-")}</td>
                                                <td style={{ width: "30%" }} className="td-p-data">{data.totalCost}</td>
                                                <td style={{ width: "25%", fontSize: "12px", whiteSpace: "nowrap" }} className="td-p-data_">{data.orderStatus}</td>

                                                {/* <td style={{ width: "25%" }} className="td-p-data_" onClick={data.active == false ? this.showUpi : this.show}>{data.status == false ? <i style={{ color: "red" }} class="fa fa-times fa-lg" aria-hidden="true"></i> : <i style={{ color: "green" }} class="fa fa-check fa-lg" aria-hidden="true"></i>}</td> */}
                                                <td style={{ width: "5%" }} className="td-p-data_" onClick={this.openOrderDetails}><i class="fa fa-eye" aria-hidden="true"></i></td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </div>
                        }
                        <div id="sameplan" className="modal-pop">
                            <form className="modal-content-log-purchase">
                                <div className="_container">
                                    <h5><b>Transaction Details</b></h5>
                                    <Row style={{ width: "100%" }} className="no-gutters">
                                        <Col xs={6}>
                                            <p className="mt-3 purchase-t-left"><b>Transaction Id</b></p>
                                            <p className="mt-2 purchase-t-left"><b>Plan Id</b></p>
                                            <p className="mt-2 purchase-t-left"><b>Plan Name</b></p>
                                            <p className="mt-2 purchase-t-left"><b>Currency</b></p>
                                            <p className="mt-2 purchase-t-left"><b>Plan Amount</b></p>
                                            <p className="mt-2 purchase-t-left"><b>Purchased On</b></p>
                                            <p className="mt-2 purchase-t-left"><b>Status</b></p>
                                        </Col>
                                        <Col xs={6}>
                                            <p className="mt-3 purchase-t-left">{this.state.transactionId}</p>
                                            <p className="mt-2 purchase-t-left">{this.state.planId}</p>
                                            <p className="mt-2 purchase-t-left">{this.state.planName}</p>
                                            <p className="mt-2 purchase-t-left">&#8377;</p>
                                            <p className="mt-2 purchase-t-left">{this.state.planAmount} </p>
                                            <p className="mt-2 purchase-t-left">{this.state.createdOn.split("T")[0].split("-").reverse().join("-")}</p>
                                            <p className="mt-2 purchase-t-left">{this.state.status == 5 ? "Refunded" : (this.state.status == 2 ? "Completed" : (this.state.status == 3 ? "Accepted" : (this.state.status == 4 ? "Rejected" : "Waiting")))}</p>
                                        </Col>
                                    </Row>
                                    <center>
                                        {this.state.status == 5 ? <div><img src={this.state.refTransactionImage} style={{ width: "120px", height: "120px" }} /><img src={this.state.transactionImage} style={{ width: "120px", height: "120px" }} /></div> : (this.state.status == 2 ? <img src={this.state.transactionImage} style={{ width: "150px", height: "150px" }} /> : (this.state.status == 3 ? <img src={this.state.transactionImage} style={{ width: "150px", height: "150px" }} /> : (this.state.status == 4 ? <img src={this.state.transactionImage} style={{ width: "150px", height: "150px" }} /> : "")))}
                                    </center>
                                    {/* <p className="mt-3 purchase-t-left"><b>Transaction Id:</b> {this.state.transactionId}</p>
                                    <p className="mt-2 purchase-t-left"><b>Plan Id:</b> {this.state.planId}</p>
                                    <p className="mt-2 purchase-t-left"><b>Plan Name:</b> {this.state.planName}</p>
                                    <p className="mt-2 purchase-t-left"><b>Currency:</b> &#8377;</p>
                                    <p className="mt-2 purchase-t-left"><b>Plan Amount:</b> {this.state.planAmount} </p>
                                    <p className="mt-2 purchase-t-left"><b>Purchased On:</b> {this.state.createdOn.split("T")[0].split("-").reverse().join("-")}</p>
                                    <p className="mt-2 purchase-t-left"><b>Status:</b> {this.state.status == false ? <i style={{ color: "red" }} class="fa fa-times fa-lg" aria-hidden="true"></i> : <i style={{ color: "green" }} class="fa fa-check fa-lg" aria-hidden="true"></i>}</p> */}
                                    <center><Button variant="default" onClick={this.closeModal} className="mt-2 deletebutton">Ok</Button><Button variant="default" onClick={this.getTransactionDetails} className="ml-3 mt-2 deletebutton">Email</Button></center>
                                </div>
                            </form>
                        </div>
                        <div id="order" className="modal-pop">
                            <form className="modal-content-log-purchase">
                                <div className="_container">
                                    <h5><b>Transaction Details</b></h5>
                                    <Row style={{ width: "100%" }} className="no-gutters">
                                        <Col xs={6}>
                                            <p className="mt-3 purchase-t-left"><b>Order Id</b></p>
                                            <p className="mt-2 purchase-t-left"><b>Size</b></p>
                                            <p className="mt-2 purchase-t-left"><b>Price</b></p>
                                            <p className="mt-2 purchase-t-left"><b>Color</b></p>
                                            <p className="mt-2 purchase-t-left"><b>Quantity</b></p>
                                            <p className="mt-2 purchase-t-left"><b>Total Cost</b></p>
                                            <p className="mt-2 purchase-t-left"><b>Purchased On</b></p>
                                            <p className="mt-2 purchase-t-left"><b>Order Status</b></p>
                                            <p className="mt-2 purchase-t-left"><b>Payment Method</b></p>
                                            <p className="mt-2 purchase-t-left"><b>Transaction Status</b></p>
                                        </Col>
                                        <Col xs={6}>
                                            <p className="mt-3 purchase-t-left">{this.state.planId}</p>
                                            <p className="mt-2 purchase-t-left">{this.state.size}</p>
                                            <p className="mt-2 purchase-t-left">Rs. {this.state.price}</p>
                                            <p className="mt-2 purchase-t-left">{this.state.color}</p>
                                            <p className="mt-2 purchase-t-left">{this.state.quantity}</p>
                                            <p className="mt-2 purchase-t-left">Rs.{this.state.totalCost} </p>
                                            <p className="mt-2 purchase-t-left">{this.state.createdOn.split("T")[0].split("-").reverse().join("-")}</p>
                                            <p className="mt-2 purchase-t-left"><span style={{ fontSize: "12px" }}>{this.state.status}</span></p>
                                            <p className="mt-2 purchase-t-left"><span style={{ fontSize: "12px" }}>{this.state.paymentMethod == null ? "Pending" : this.state.paymentMethod}</span></p>
                                            <p className="mt-2 purchase-t-left"><span style={{ fontSize: "12px" }}>{this.state.transactionStatus == 1 ? "Pending" : (this.state.transactionStatus == 2 ? "Completed" : "Rejected")}</span></p>
                                        </Col>
                                    </Row>
                                    {/* <center>
                                        {this.state.status == 5 ? <div><img src={this.state.refTransactionImage} style={{ width: "120px", height: "120px" }} /><img src={this.state.transactionImage} style={{ width: "120px", height: "120px" }} /></div> : (this.state.status == 2 ? <img src={this.state.transactionImage} style={{ width: "150px", height: "150px" }} /> : (this.state.status == 3 ? <img src={this.state.transactionImage} style={{ width: "150px", height: "150px" }} /> : (this.state.status == 4 ? <img src={this.state.transactionImage} style={{ width: "150px", height: "150px" }} /> : "")))}
                                    </center> */}
                                    {/* <center><Button variant="default" onClick={this.closeOrderdetails} className="mt-2 deletebutton">Ok</Button></center> */}
                                    <center><Button variant="default" onClick={this.closeOrderdetails} className="mt-2 deletebutton">OK</Button><Link to={`/vieworder/${this.state.planId}`}><Button variant="default" disabled={this.state.transactionStatus == 1 ? true : false} className="ml-3 mt-2 deletebutton">Track</Button></Link></center>
                                </div>
                            </form>
                        </div>
                        <div id="trans" className="modal-pop" >
                            <form className="modal-content-log">
                                <div className="_container">
                                    <p className="mt-2">{this.state.emailText}</p>
                                    <center><Button variant="default" onClick={this.showNone} className="deletebutton">OK</Button></center>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
                <div className="mb-bottom"></div>
                <BottomMenu />
            </div >

        );
    };
}