import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col, InputGroup, FormControl } from 'react-bootstrap';
import loginlogo from '../images/login-logo.png';
import tshirt from '../images/tshirt.jpeg';
import tshirtnavy from '../images/tshirtnavy.jpeg';
import axios from 'axios';
import back from '../images/backarrow.png';
import bhim from '../images/BHIM-UPI.jpg';
import { Link, Redirect } from 'react-router-dom';
import { config } from '../Config/Config';
import BottomMenu from "../BottomMenu/BottomMenu";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


const PlaceOrderForm = (props) => {
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [size, setSize] = useState('M');
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState(599);
    const [totalCost, setTotalCost] = useState(599 + 150)
    const [address, setAddress] = useState('');
    const [redeemedPoints, setRedeemedPoints] = useState();
    const [imageOnTshirt, setImageOnTshirt] = useState('');
    const [color, setColor] = useState('White');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [qr, setQr] = useState('');
    const [picture, setPicture] = useState('')
    const [title, setTitle] = useState('')
    const [paymentMethod, setPaymentMethod] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [expDate, setExpDate] = useState('');
    const [cvv, setCvv] = useState('');
    const [upiId, setUpiId] = useState('');
    const [orderId, setOrderId] = useState('');
    const [showOrder, setShowOrder] = useState(true);
    const [showPayment, setShowPayment] = useState(false);

    useEffect(() => {
        getTshirtOrderById();
        getArticleImageByPostId();
        payment();
    }, [])
    const handleSizeChange = (event) => {
        setSize(event.target.value);
    };
    const handleColorChange = (event) => {
        setColor(event.target.value);
    };
    const handleQuantityChange = (event) => {
        const qty = parseInt(event.target.value);
        setQuantity(qty);
        setTotalCost(150 * qty + qty * 599)
    };
    const handleChangeCVV = (e) => {
        const value = e.target.value;
        setCvv(value.replace(/./g, "•"));
    };
    const handleAddressChange = (event) => {
        setAddress(event.target.value);
    };

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };
    const handlePaymentMethodChange = (method) => {
        setPaymentMethod(method);
    };
    const getArticleImageByPostId = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + `user/getArticleImageByPostId/${props.match.params.id}`, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    setImageOnTshirt(response.data.post[0]?.image);
                    setTitle(response.data.post[0]?.title);
                    setName(response.data.userDetails[0]?.name)
                    setEmail(response.data.userDetails[0]?.email)
                    setPhoneNumber(response.data.userDetails[0]?.phone)
                }
            })
            .catch(function (error) {
                console.log("error", error);
            })
    };
    const getTshirtOrderById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        };

        var payload = {
            "articleId": parseInt(props.match.params.id)
        };

        axios.post(config.userUrl + `user/getTshirtOrderById`, payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    if (Array.isArray(response.data.order) && response.data.order.length === 0) {
                        // If order is an empty array, setShowOrder(true)
                        console.log("Order is empty");
                        setShowOrder(true);
                    } else if (response.data.order && response.data.order.id && response.data.order?.transactionStatus === 1) {
                        // If order is not empty and has an 'id' field, setShowOrder(false)
                        console.log("Order exists");
                        setShowOrder(false);
                        setShowPayment(true);
                        setOrderId(response.data.order?.orderId);
                        setSize(response.data.order?.size)
                        setPrice(response.data.order?.price)
                        setQuantity(response.data.order?.quantity)
                        setColor(response.data.order?.color)
                        //  setAddress(response.data.order?.address)

                    } else {
                        // Default fallback if the structure doesn't match expected
                        setShowOrder(false);
                        setPrice(response.data.order?.price)
                        setOrderId(response.data.order?.orderId);
                    }
                } else {
                    console.log(response.data);
                }
            })
            .catch(function (error) {
                console.log("Error", error); // Log the error for debugging purposes
            });
    };
    const handleSubmit = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        var payload = {
            "size": size,
            "quantity": quantity,
            "color": color,
            "price": price,
            "totalCost": totalCost,
            "address": address,
            "phoneNumber": phoneNumber,
            "name": name,
            "email": email,
            "articleId": props.match.params.id,
            "transactionStatus": 1,
            "imageOnTshirt": imageOnTshirt,
            "type":"Tshirt",
            "redeemedPoints": redeemedPoints ? redeemedPoints : null
        }
        console.log("payload", payload)
        axios.post(config.userUrl + 'user/placeTshirtOrder', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    setOrderId(response.data.order?.orderId)
                    setShowOrder(false);
                }
            })
            .catch(function (error) {
                console.log("error", error);
            })
    }
    const postTransactionDetails = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        var payload = {
            "orderId": orderId,
            "paymentMethod": "UPI",
            "transactionImage": picture,
            "transactionStatus": 2,
        }
        console.log("payload", payload)
        axios.post(config.userUrl + 'user/updateOrderTransactionStatus', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    enqueueSnackbar('Transaction Completed Successfully', { variant: "success" })
                    setRedirect(true);
                }
            })
            .catch(function (error) {
                console.log("error", error);
            })
    }
    const payment = () => {
        const qrcode = require('qrcode');
        const vpa = config.vpa;
        const amount = totalCost + '.00';
        const upiUrl = `upi://pay?pa=${encodeURIComponent(vpa)}&mc=yourMerchantCode&tid=yourTransactionId&tr=yourReferenceId&tn=Payment%20Description&am=${encodeURIComponent(amount)}&cu=INR&url=yourCallbackUrl`;


        qrcode.toDataURL(upiUrl, (err, dataUrl) => {
            if (err) throw err;
            setQr(dataUrl)
        });
    };
    const uploadImage = async (image) => {
        let data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "sceem_")
        data.append("cloud_name", "sceem")
        const resp = await fetch(`https://api.cloudinary.com/v1_1/sceem/image/upload`, {
            method: "post",
            body: data
        })
        let res = await resp.json();
        return res.secure_url
    }

    const handleImage = async (e) => {
        if (e.target.files && e.target.files[0]) {
            const res = await uploadImage(e.target.files[0]);
            if (res) {
                setPicture(res)
            }
        }
    }
    const showPublish = () => {
        document.getElementById('publish').style.display = 'flex'
    }
    const showNone = () => {
        document.getElementById('publish').style.display = 'none'
    }
    if (redirect) {
        return <Redirect
            to={{
                pathname: `/purchases`
            }}
        />
    }
    return (
        <div >
            <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
            {showOrder ?
                <div style={{ marginTop: "2.5rem" }}>
                    <div className="your-jitbits"><Link to={{ pathname: `/article/${title.replace(/ /g, "_")}/${props.match.params.id}` }} className="mr-1 link"><img alt="" src={back} style={{ width: "30px" }} /></Link>Order Tshirt</div>
                    <Form className="ml-3 mr-3" style={{ fontFamily: "Noto Sans", marginTop: "-5px" }}>
                        <div style={{ border: "1px solid gray", borderRadius: "15px", padding: "5px" }}>
                            <Row style={{ width: "100%" }} className='no-gutters'>
                                <Col xs={5}>
                                    <center>
                                        <div style={{ position: 'relative', display: 'grid' }}>
                                            <img src={color === "White" ? tshirt : tshirtnavy} style={{ width: "100%" }} alt="T-shirt" />
                                            <img
                                                src={config.userUrl + imageOnTshirt}
                                                style={color === "White" ? {
                                                    position: 'absolute',
                                                    top: '39%',
                                                    left: '38%',
                                                    width: '25%',
                                                    height: 'auto',
                                                } : {
                                                    position: 'absolute',
                                                    top: '39%',
                                                    left: '38%',
                                                    width: '25%',
                                                    height: 'auto',
                                                    filter: 'invert(1)',
                                                }}
                                                alt="Overlay"
                                            />
                                            <span style={{ marginTop: "-10px", fontSize: "14px", fontfamily: "Noto Sans" }}>Rs.{price}</span>
                                        </div>
                                    </center>
                                </Col>
                                <Col xs={7}>
                                    <Row style={{ width: "100%" }} className='no-gutters'>
                                        <Col>

                                            <label className="label-order-text">Color</label>
                                            <select type="text" className='form-control form-fontsize' value={color} onChange={handleColorChange}>
                                                <option>White</option>
                                                <option>Navy Blue</option>
                                            </select>
                                        </Col>
                                        <Col>
                                            <label className="label-order-text">Size</label>
                                            <select type="text" className='form-control form-fontsize' value={size} onChange={handleSizeChange}>
                                                <option>S</option>
                                                <option>M</option>
                                                <option>L</option>
                                                <option>XL</option>
                                                <option>XXL</option>
                                            </select>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }} className='no-gutters'>
                                        <Col>
                                            <label className="label-order-text">Quantity</label>
                                            <select type="text" className='form-control form-fontsize' value={quantity} onChange={handleQuantityChange}>
                                                {[1, 2, 3, 4, 5].map((qty) => (
                                                    <option key={qty} value={qty}>
                                                        {qty}
                                                    </option>
                                                ))}
                                            </select>
                                        </Col>
                                        <Col>
                                            <label className="label-order-text" for="price">Total Cost</label>
                                            <input type="text" className='form-control form-fontsize' readOnly value={` Rs.${totalCost}`} />

                                            <div style={{ fontSize: "9px" }}>Incl. Rs.150, Shipping
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div style={{ border: "1px solid gray", borderRadius: "15px", padding: "5px" }}>
                            <label className="label-order-text">Name</label>
                            <input
                                className='form-control label-order-text'
                                type="text"
                                placeholder="Name"
                                value={name}
                                readOnly
                            />
                            <label className="label-order-text">Address</label>
                            <textarea
                                className='form-control label-order-text'
                                rows={3}
                                style={{ resize: "none", width: "100%", padding: "10px", borderRadius: "4px" }}
                                placeholder="Enter your address"
                                value={address}
                                onChange={handleAddressChange}
                            />

                            <label className="label-order-text">Email</label>
                            <input
                                className='form-control label-order-text'
                                type="email"
                                placeholder="Enter email"
                                value={email}
                                readOnly
                            />
                            <label className="label-order-text">Phone Number</label>
                            <input
                                className='form-control label-order-text'
                                type="text"
                                placeholder="Enter your phone number"
                                value={phoneNumber}
                                readOnly
                            />
                        </div>
                        <center>
                            {address !== "" ?
                                <Button variant="default" className="mt-3 track-btn" onClick={handleSubmit}>Submit</Button>
                                :
                                <Button variant="default" disabled="true" className="mt-3 track-btn" onClick={handleSubmit}>Submit</Button>
                            }
                        </center>
                    </Form>
                </div>
                :
                <div style={{ marginTop: "2.5rem" }}>
                    <div className='your-jitbits'>
                        {showPayment ?
                            <Link to={{ pathname: `/article/${title.replace(/ /g, "_")}/${props.match.params.id}` }} className="mr-1 link"><img alt="" src={back} style={{ width: "30px" }} /></Link> : ""}
                        UPI Payment</div>
                    {/* <div className='payment-details-container'>
                        <div style={{ display: "flex", justifyContent: "space-between", fontFamily: "Noto sans" }}>
                            <div></div>
                            <div>Amount: Rs.{price}</div>
                        </div>
                        <div className="mt-2 payment-methods">
                            <div
                                className={paymentMethod === 'Card' ? 'active' : ''}
                                onClick={() => handlePaymentMethodChange('Card')}
                            >
                                Card
                            </div>
                            <div
                                className={paymentMethod === 'Upi' ? 'active' : ''}
                                onClick={() => handlePaymentMethodChange('Upi')}
                            >
                                UPI
                            </div>
                        </div>
                        <div className="mt-3 payment-form">
                            {paymentMethod === 'Card' && (
                                <div>
                                    <label>Name on Card</label>
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    <label>Card Number</label>
                                    <input
                                        type="text"
                                        placeholder="Ex: 1234 1234 1234 1234"
                                        value={formatCardNumber(cardNumber)}
                                        onChange={(e) => setCardNumber(e.target.value.replace(/\s/g, ''))}
                                    />
                                    <label>Exp Date</label>
                                    <input
                                        type="text"
                                        placeholder="MM/YYYY"
                                        value={expDate}
                                        onChange={(e) => setExpDate(e.target.value)}
                                    />
                                    <label>CVV</label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        placeholder="CVV"
                                        value={cvv}
                                        onChange={handleChangeCVV}
                                        maxLength="4"
                                    />
                                </div>
                            )}
                            {paymentMethod === 'Upi' && (
                                <div>
                                    <label>UPI ID</label>
                                    <input
                                        type="text"
                                        placeholder="UPI ID"
                                        value={upiId}
                                        onChange={(e) => setUpiId(e.target.value)}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="mt-2" style={{ display: "flex", justifyContent: "space-between" }}>
                            <div><Button variant="default" className="mt-1 payment-back-button" onClick={() => setShowOrder(true)}>&nbsp;Back&nbsp;</Button></div>
                            {address !== '' && phoneNumber !== '' ?
                                <Button variant="default" className="mt-1 payment-submit-button" onClick={handleSubmit}>Submit</Button>
                                : <Button variant="default" className="mt-1 payment-submit-button" disabled='true'>Submit</Button>
                            }
                        </div>
                    </div> */}
                    <div style={{ marginLeft: "15px", marginRight: "15px" }}>
                        {/* <form>
                            <label className="mt-1 ml-1">Enter UPI ID</label>
                            <input type="text" placeholder="Ex: MobileNumber@upi" className="form-control" name="upiId" onChange={this.onChange} value={this.state.upiId} />
                        </form> */}
                        <center className="mt-3">
                            <div className="nebutech-analytics-llp"><b>{config.companyName.toUpperCase()}</b></div>
                            <img src={qr} style={{ width: "50%" }} />
                            <div className="mt-1 scan">{config.vpa}</div>
                            <div className="mt-3 scan">Scan and Pay with any UPI app</div>
                            <div className="mt-2 scan">Purchase of Tshirt</div>
                            <div className="mt-1 nebutech-analytics-llp"><b>&#8377; &nbsp;{totalCost}</b></div>
                        </center>
                        <div><Button variant="default" onClick={() => showPublish()} className="upload-trans-center">Upload Transaction</Button></div>


                    </div>
                </div>}
            <div id="publish" className="modal-pop">
                <form className="modal-content-log-height">
                    <div className="_container">
                        <center style={{ height: "150px" }}>
                            <label htmlFor="upload-button">
                                {picture != "" ? (
                                    <img src={picture} style={{ width: "100px", height: "100px" }} />
                                ) : (
                                    <>
                                        <i class="fa fa-upload fa-2x" aria-hidden="true"></i>
                                        <div className="up-load">Upload Image</div>
                                    </>
                                )}
                            </label>
                            <input
                                type="file"
                                style={{ display: "none" }}
                                id="upload-button"
                                name="image"
                                onChange={(e) => handleImage(e)}
                            />
                        </center>
                        <center>{picture !== "" ? <Button variant="default" onClick={() => postTransactionDetails()} className="mb-2 cancelbutton">Yes</Button>
                            : <Button variant="default" disabled={true} className="mb-2 cancelbutton">Yes</Button>}&nbsp;&nbsp;
                            <Button variant="default" onClick={showNone} className="mb-2 deletebutton">No</Button></center>
                    </div>
                </form>
            </div>
            <BottomMenu />
        </div>
    );
};

export default PlaceOrderForm;