import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Login from '../src/Login/Login';
import JitBits from './JitBits/JitBits';
import ArticleDescription from './LandingPage/ArticleDescription';
import AuthorDetails from './LandingPage/AuthorDetails';
import Authors from './LandingPage/Authors';
import DetailedAdvertisement from './LandingPage/DetailedAdvertisement';
import FavAuthors from './LandingPage/FavAuthors';
import FavAuthorsList from './LandingPage/FavAuthorsList';
import FavBits from './LandingPage/FavBits';
import FavReviewer from './LandingPage/FavReviewer';
import FollowingAuthors from './LandingPage/FollowingAuthors';
import Home from './LandingPage/Home';
import Interests from './LandingPage/Interests';
import LandingPage from './LandingPage/LandingPage';
import NewArticleDescription from './LandingPage/NewArticleDescription';
import PageNotFound from './LandingPage/PageNotFound';
import RecommendedAuthors from './LandingPage/RecommendedAuthors';
import Userscreen from './LandingPage/Userscreen';
import EditProfile from './Login/EditProfile';
import ForgotPassword from './Login/ForgotPassword';
import NewPassword from './Login/NewPassword';
import Profile from './Login/Profile';
import VerifyEmail from './Login/VerifyEmail';
import NotificationBell from './NotificationBell/NotificationBell';
import Notifications from './Notifications/Notifications';
import Bar from './Poll/Bar';
import NewPoll from './Poll/NewPoll';
import PollHome from './Poll/PollHome';
import HistoryPolls from './Poll/HistoryPolls';
import PollQuestion from './Poll/PollQuestion';
import PollResults from './Poll/PollResults';
import PollTopic from './Poll/PollTopic';
import Pricing from './Pricing/Pricing';
import Preferences from './Settings/Preferences';
import Settings from './Settings/Settings';
import SignUp from './SignUp/SignUp';
import About from './TermsAndConditions/About';
import DataPrivacy from './TermsAndConditions/DataPrivacy';
import PrivacyAndSecurity from './TermsAndConditions/PrivacyAndSecurity';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import CurrentPolls from './Poll/CurrentPolls';
import Popup from './LandingPage/Popup';
import ExpressInterest from './LandingPage/ExpressInterest';
import AskQuestions from './LandingPage/AskQuestions';
import AlternateVerify from './Login/AlternateVerify';
import CheckAnswers from './LandingPage/CheckAnswers';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import CreateOpportunity from './LandingPage/CreateOpportunity';
import MyQuestions from './JitBits/MyQuestions';
import ViewOpportunity from './LandingPage/ViewOpportunities';
import DetailedOpportunity from './LandingPage/DetailedOpportunity';
import ReferAFriend from './LandingPage/ReferAFriend';
import ReferralStatus from './LandingPage/ReferralStatus';
import Subscription from './Pricing/Subscription';
import Purchases from './LandingPage/Purchases';
import RegisteredUsers from './LandingPage/RegisteredUsers';
import CreateGroup from './LandingPage/CreateGroup';
import ViewGroup from './LandingPage/ViewGroup';
import InviteToGroup from './LandingPage/InviteToGroup';
import ViewAllGroups from './LandingPage/ViewAllGroups';
import Sceemlore from './LandingPage/Sceemlore';
import SceemloreById from './LandingPage/SceemloreById';
import SceemloreEachCard from './LandingPage/SceemloreEachCard';
import ThanksForRegistering from './LandingPage/ThanksForRegistering';
import ReviewScreens from './LandingPage/ReviewScreens';
import RequestForAdvertisement from './LandingPage/RequestForAdvertisement';
import AdvertisementStatus from './LandingPage/AdvertisementStatus';
import CurrentPricingPlan from './Pricing/CurrentPricingPlan';
import Games from './LandingPage/Games';
import ShowGame from './LandingPage/ShowGame';
import Leaderboard from './LandingPage/Leaderboard';
import RedeemPoints from './LandingPage/RedeemPoints';
import RewardsHistory from './LandingPage/RewardsHistory';
import RewardsPointsHistory from './LandingPage/RewardPointsHistory';
import showTerm from './LandingPage/ShowTerm';
import ScrambledGame from './LandingPage/ScrambledGame';
import SpinTheWheel from './LandingPage/SpinTheWheel';
import InviteGroup from './LandingPage/InviteGroup';
import Invitations from './LandingPage/Invitations';
import InvitedGroupMembers from './LandingPage/InvitedGroupMembers';
import UPI from './Pricing/UPI';
import UpiDetails from './Pricing/UpiDetails';
import Refund from './Pricing/Refund';
import RefundTransaction from './Pricing/RefundTransaction';
import SequenceGame from './LandingPage/SequenceGame';
import ProfileScreen from './LandingPage/ProfileScreen';
import Register from './SignUp/Register';
import UpdateProfile from './Login/UpdateProfile';
import SearchUsers from './JitBits/SearchUsers';
import SearchResults from './JitBits/SearchResults';
import Quiz from './LandingPage/Quiz';
import SMEOfferings from './LandingPage/SMEOfferings';
import SMEProfile from './LandingPage/SMEProfile';
import SMEConnect from './LandingPage/SMEConnect';
import SMESlots from './LandingPage/SMESlots';
import SMEPlanned from './LandingPage/SMEPlanned';
import SMERequests from './LandingPage/SMERequests';
import UpdateSmeProfile from './LandingPage/UpdateSmeProfile';
import SmeUserProfile from './LandingPage/SmeUserProfile';
import ConfigureFees from './LandingPage/ConfigureFees';
import PromoteSmeProfile from './LandingPage/PromoteSmeProfile';
import IncidentStatus from './LandingPage/IncidentStatus';
import DetailedIncident from './LandingPage/DetailedIncident';
import ReportIssue from './LandingPage/ReportIssue';
//import ErrorBoundary from './LandingPage/ErrorBoundary';
import SmeEventData from './LandingPage/SMEEventData';
import ViewSmeEvents from './LandingPage/ViewSmeEvents';
import DetailedEvent from './LandingPage/DetailedEvent';
import ViewMyEvents from './LandingPage/ViewMyEvents';
// import EventCard from './LandingPage/EventCard';
// import TextareaWithCustomMenu from './LandingPage/TextareaWithCustomMenu';
import DetailedAdv from './LandingPage/DetailedAdv';
import ArticleAdvertisement from './LandingPage/ArticleAdvertisement';
import DetailedArticle from './LandingPage/DetailedArticle';
import PlaceOrderForm from './LandingPage/PlaceOrderForm';
import TrackingLink from './LandingPage/TrackingLink';
import ViewTshirtOrder from './LandingPage/ViewTshirtOrder';


export default class Routes extends React.Component {
    constructor(props) {
        super(props);
    }

    renderContent = () => {
        if (isMobile) {
            return (
                <Switch>
                    <Route exact path="/" component={LandingPage} />
                    <Route path="/login" component={Login} />
                    <Route path="/forgot" component={ForgotPassword} />
                    <Route path="/verify" component={VerifyEmail} />
                    <Route path="/sort" component={AlternateVerify} />
                    <Route path="/newpassword" component={NewPassword} />
                    <Route path="/addgroup" component={CreateGroup} />
                    <Route path="/viewgroup" component={ViewGroup} />
                    <Route path="/signup" component={Register} />
                    <Route path="/addprofiledetails" component={UpdateProfile} />
                    <Route path="/profile" component={Profile} />
                    <Route path="/interests" component={Interests} />
                    <Route exact path="/authors" component={Authors} />
                    <Route path="/home" component={Home} />
                    {/* <Route path="/article/:id" component={ArticleDescription} /> */}
                    {/* <Route path="/article/:title/:id" render={(props) => <ArticleDescription key={props.match.params.id} {...props} />} /> */}
                    <Route path="/article/:title/:id" render={(props) => <DetailedArticle key={props.match.params.id} {...props} />} />
                    <Route path="/authordetails/:id" component={AuthorDetails} />
                    <Route path="/userdetails" component={ProfileScreen} />
                    <Route path="/favauthor" component={FavAuthors} />
                    <Route path="/favauthorlist" component={FavAuthorsList} />
                    <Route path="/favreviewer" component={FavReviewer} />
                    <Route path="/adv/:id" component={DetailedAdvertisement} />
                    <Route path="/terms" component={TermsAndConditions} />
                    <Route path="/settings" component={Settings} />
                    <Route path="/about" component={About} />
                    <Route path="/notifications" component={Notifications} />
                    <Route path="/pricing" component={Pricing} />
                    <Route path="/qrcode" component={UPI} />
                    <Route path="/upidetails" component={UpiDetails} />
                    <Route path="/refund" component={Refund} />
                    <Route path="/reftransaction" component={RefundTransaction} />
                    <Route path="/currentpricing" component={CurrentPricingPlan} />
                    <Route path="/pollhome" component={PollHome} />
                    <Route exact path='/polls/historypolls' component={HistoryPolls} />
                    <Route exact path='/polls/currentpolls' component={CurrentPolls} />
                    <Route path="/polltopic" component={PollTopic} />
                    <Route path="/pollquestion/:id" component={PollQuestion} />
                    <Route path="/pollresults/:id" component={PollResults} />
                    <Route path="/jitbits" component={JitBits} />
                    <Route path="/polldata" component={NewPoll} />
                    <Route path="/editprofile" component={EditProfile} />
                    <Route path="/favbits" component={FavBits} />
                    <Route path="/preferences" component={Preferences} />
                    <Route path="/security" component={PrivacyAndSecurity} />
                    <Route path="/dataprivacy" component={DataPrivacy} />
                    <Route path="/askquestion" component={AskQuestions} />
                    <Route path="/checkanswers" component={CheckAnswers} />
                    {/* <Route path="/description/:id" component={NewArticleDescription} /> */}
                    <Route path="/usernotifications" component={NotificationBell} />
                    <Route exact path="/authors/recommended" component={RecommendedAuthors} />
                    <Route exact path="/authors/following" component={FollowingAuthors} />
                    <Route path="/express" component={ExpressInterest} />
                    <Route path="/addgroup" component={CreateGroup} />
                    <Route path="/viewgroup/:id" component={ViewGroup} />
                    <Route exact path="/group/invitegroup" component={InviteGroup} />
                    <Route exact path="/group/invited" component={InvitedGroupMembers} />
                    <Route path="/sentinvitations" component={Invitations} />
                    <Route path="/invitetogroup" component={InviteToGroup} />
                    <Route path="/viewallgroups" component={ViewAllGroups} />
                    <Route exact path="/sceemlore" component={Sceemlore} />
                    <Route exact path="/sceemlore/:id" component={SceemloreById} />
                    <Route path="/eachcard/:id" component={SceemloreEachCard} />
                    <Route path="/thanksforregistering/:id" component={ThanksForRegistering} />
                    <Route exact path="/reviewcard/:id" component={ReviewScreens} />
                    <Route path="/requestforadvertisement" component={RequestForAdvertisement} />
                    <Route path="/viewadvertisements" component={AdvertisementStatus} />
                    <Route path="/games" component={Games} />
                    <Route path="/game/:id" component={ShowGame} />
                    <Route path="/scrambledgame/:id" component={ScrambledGame} />
                    <Route path="/spinthewheel/:id" component={SpinTheWheel} />
                    <Route path="/sequencegame/:id" component={SequenceGame} />
                    <Route path="/leaderboard" component={Leaderboard} />
                    <Route path="/redeem" component={RedeemPoints} />
                    <Route path="/rewardshistory" component={RewardsHistory} />
                    <Route path="/rewardpointshistory" component={RewardsPointsHistory} />
                    <Route path="/term/:id" component={showTerm} />
                    <Route path="/search" component={SearchUsers} />
                    <Route path="/getprofilebyid/:id" component={SearchResults} />
                    <Route path="/quiz" component={Quiz} />
                    <Route path="/smeregister" component={SMEOfferings} />
                    <Route path="/smeprofile" component={SMEProfile} />
                    <Route path="/smeconnect" component={SMEConnect} />
                    <Route path="/smeslot" component={SMESlots} />
                    <Route path="/smeplanned" component={SMEPlanned} />
                    <Route path="/smerequests" component={SMERequests} />
                    <Route path="/profilesme/:id" component={SmeUserProfile} />
                    <Route path="/smeupdateregister" component={UpdateSmeProfile} />
                    <Route path="/smeconfigurefees" component={ConfigureFees} />
                    <Route path="/smepromoteprofile" component={PromoteSmeProfile} />
                    <Route path="/error" component={ReportIssue} />
                    <Route path="/showAllIncidents" component={IncidentStatus} />
                    <Route path="/incident/:id" component={DetailedIncident} />
                    <Route path="/detailedadv/:id" component={DetailedAdv} />
                    <Route path="/advertisement/:id" component={ArticleAdvertisement} />
                    <Route path="/ordertshirt/:id" component={PlaceOrderForm} />
                    <Route path="/vieworder/:id" component={ViewTshirtOrder} />
                    <Route path="/trackinglink/:id" component={TrackingLink} />
                    <Route path="*" component={PageNotFound} />
                </Switch>
            )
        }
        return <center className="no-connection"> <h4> Please open in mobile...</h4> </center>
    }
    // render() {
    //     return this.renderContent();
    // }
    render() {
        // const style = {
        //     '@media (max-width: 500px)': {
        //         : '',
        //       },
        // }
        return (
            <Switch>
                <Route exact path="/" component={LandingPage} />
                <Route path="/login" component={Login} />
                <Route path="/forgot" component={ForgotPassword} />
                <Route path="/verify" component={VerifyEmail} />
                <Route path="/alternateverify" component={AlternateVerify} />
                <Route path="/newpassword" component={NewPassword} />
                <Route path="/signup" component={Register} />
                <Route path="/addprofiledetails" component={UpdateProfile} />
                <Route path="/profile" component={Profile} />
                <Route path="/interests" component={Interests} />
                <Route path="/purchases" component={Purchases} />
                <Route exact path="/authors" component={Authors} />
                <Route path="/home" component={Home} />
                {/* <Route path="/article/:title/:id" component={ArticleDescription} /> */}
                {/* <Route path="/article/:title/:id" render={(props) => <ArticleDescription key={props.match.params.id} {...props} />} /> */}
                <Route path="/article/:title/:id" render={(props) => <DetailedArticle key={props.match.params.id} {...props} />} />
                <Route path="/opportunity/:id" component={DetailedOpportunity} />
                <Route path="/authordetails/:id" component={AuthorDetails} />
                <Route path="/userdetails" component={ProfileScreen} />
                <Route path="/favauthor" component={FavAuthors} />
                <Route path="/favauthorlist" component={FavAuthorsList} />
                <Route path="/favreviewer" component={FavReviewer} />
                <Route path="/adv/:id" component={DetailedAdvertisement} />
                <Route path="/terms" component={TermsAndConditions} />
                <Route path="/subscription" component={Subscription} />
                <Route path="/settings" component={Settings} />
                <Route path="/createopportunity" component={CreateOpportunity} />
                <Route path="/viewopportunity" component={ViewOpportunity} />
                <Route path="/referafriend" component={ReferAFriend} />
                <Route path="/referralstatus" component={ReferralStatus} />
                <Route path="/about" component={About} />
                <Route path="/notifications" component={Notifications} />
                <Route path="/pricing" component={Pricing} />
                <Route path="/qrcode" component={UPI} />
                <Route path="/upidetails" component={UpiDetails} />
                <Route path="/refund" component={Refund} />
                <Route path="/reftransaction" component={RefundTransaction} />
                <Route path="/currentpricing" component={CurrentPricingPlan} />
                <Route path="/pollhome" component={PollHome} />
                <Route exact path='/polls/historypolls' component={HistoryPolls} />
                <Route exact path='/polls/currentpolls' component={CurrentPolls} />
                <Route path="/polltopic" component={PollTopic} />
                <Route path="/pollquestion/:id" component={PollQuestion} />
                <Route path="/pollresults/:id" component={PollResults} />
                <Route path="/jitbits" component={JitBits} />
                <Route path="/registeredusers" component={RegisteredUsers} />
                <Route path="/polldata" component={NewPoll} />
                <Route path="/editprofile" component={EditProfile} />
                <Route path="/favbits" component={FavBits} />
                <Route path="/preferences" component={Preferences} />
                <Route path="/security" component={PrivacyAndSecurity} />
                <Route path="/dataprivacy" component={DataPrivacy} />
                <Route path="/askquestion" component={AskQuestions} />
                <Route path="/checkanswers" component={CheckAnswers} />
                <Route path="/myquestions" component={MyQuestions} />
                {/* <Route path="/description/:id" component={NewArticleDescription} /> */}
                <Route path="/usernotifications" component={NotificationBell} />
                <Route exact path="/authors/recommended" component={RecommendedAuthors} />
                <Route exact path="/authors/following" component={FollowingAuthors} />
                <Route path="/express" component={ExpressInterest} />
                <Route path="/addgroup" component={CreateGroup} />
                <Route path="/viewgroup/:id" component={ViewGroup} />
                <Route exact path="/group/invitegroup" component={InviteGroup} />
                <Route exact path="/group/invited" component={InvitedGroupMembers} />
                <Route path="/sentinvitations" component={Invitations} />
                <Route path="/invitetogroup" component={InviteToGroup} />
                <Route path="/viewallgroups" component={ViewAllGroups} />
                <Route exact path="/sceemlore" component={Sceemlore} />
                <Route exact path="/sceemlore/:id" component={SceemloreById} />
                <Route path="/eachcard/:id" component={SceemloreEachCard} />
                <Route path="/thanksforregistering/:id" component={ThanksForRegistering} />
                <Route exact path="/reviewcard/:id" component={ReviewScreens} />
                <Route path="/requestforadvertisement" component={RequestForAdvertisement} />
                <Route path="/viewadvertisements" component={AdvertisementStatus} />
                <Route path="/games" component={Games} />
                <Route path="/game/:id" component={ShowGame} />
                <Route path="/scrambledgame/:id" component={ScrambledGame} />
                <Route path="/spinthewheel/:id" component={SpinTheWheel} />
                <Route path="/sequencegame/:id" component={SequenceGame} />
                <Route path="/leaderboard" component={Leaderboard} />
                <Route path="/redeem" component={RedeemPoints} />
                <Route path="/rewardshistory" component={RewardsHistory} />
                <Route path="/rewardpointshistory" component={RewardsPointsHistory} />
                <Route path="/term/:id" component={showTerm} />
                <Route path="/search" component={SearchUsers} />
                <Route path="/getprofilebyid/:id" component={SearchResults} />
                <Route path="/quiz" component={Quiz} />
                <Route path="/smeregister" component={SMEOfferings} />
                <Route path="/smeprofile" component={SMEProfile} />
                <Route path="/smeconnect" component={SMEConnect} />
                <Route path="/smeslot" component={SMESlots} />
                <Route path="/smeplanned" component={SMEPlanned} />
                <Route path="/smerequests" component={SMERequests} />
                <Route path="/smeupdateregister" component={UpdateSmeProfile} />
                <Route path="/profilesme/:id" component={SmeUserProfile} />
                <Route path="/smecreateevent" component={SmeEventData} />
                <Route path="/viewmyevents" component={ViewMyEvents} />
                <Route path="/viewsmeevents/:id" component={ViewSmeEvents} />
                <Route path="/detailedevent/:id" component={DetailedEvent} />
                <Route path="/smeconfigurefees" component={ConfigureFees} />
                <Route path="/smepromoteprofile" component={PromoteSmeProfile} />
                <Route path="/error" component={ReportIssue} />
                <Route path="/showAllIncidents" component={IncidentStatus} />
                <Route path="/incident/:id" component={DetailedIncident} />
                <Route path="/detailedadv/:id" component={DetailedAdv} />
                <Route path="/advertisement/:id" component={ArticleAdvertisement} />
                <Route path="/ordertshirt/:id" component={PlaceOrderForm} />
                <Route path="/vieworder/:id" component={ViewTshirtOrder} />
                <Route path="/trackinglink/:id" component={TrackingLink} />
                <Route path="*" component={PageNotFound} />
                 </Switch >
        )
    }
}