import React, { useState, useEffect } from 'react';
import './TrackingLink.css';
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment-timezone';
import { config } from '../Config/Config';
import axios from 'axios';
import {Link} from 'react-router-dom';
import back from '../images/backarrow.png';
import loginlogo from '../images/login-logo.png';
import BottomMenu from '../BottomMenu/BottomMenu';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

function TrackingLink(props) {

    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [orderStatus, setOrderStatus] = useState('');
    const [orderId, setOrderId] = useState(props.match.params.id);
    const [createdAt, setCreatedAt] = useState('');
    const [updatedAt, setUpdatedAt] = useState('');
    const [deliveryDate, setDeliveryDate] = useState('');
    const [dispatchedDate, setDispatchedDate] = useState('');
    const [packedOnDate, setPackedOnDate] = useState('');
    const [trackingNumber, setTrackingNumber] = useState('');
    const [showOrder, setShowOrder] = useState(true);
    const [errorMessage, setErrorMessage] = useState(''); // For displaying error message
    const [isOrderNotFound, setIsOrderNotFound] = useState(false);  // State to manage order not found

    useEffect(() => {
        getOrderById();
    }, []);

    const getOrderById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }

        if (!orderId) {
            setErrorMessage('Please enter a valid order ID.');
            return;
        }
        var payload = {
            "orderId": orderId
        }
        axios.post(config.userUrl + `user/getOrderByOrderId`, payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true && response.data.order.length > 0) {
                    setShowOrder(false);
                    setOrderId(response.data.order[0]?.orderId);
                    setUpdatedAt(response.data.order[0]?.updatedAt);
                    setCreatedAt(response.data.order[0]?.createdAt);
                    setDispatchedDate(response.data.order[0]?.dispatchedDate);
                    setPackedOnDate(response.data.order[0]?.packedOnDate);
                    setDeliveryDate(response.data.order[0]?.deliveryDate);
                    setOrderStatus(response.data.order[0]?.orderStatus);
                    setTrackingNumber(response.data.order[0]?.trackingNumber)
                    setIsOrderNotFound(false);  // Reset "Order Not Found" state
                    setErrorMessage(''); // Clear any previous error message
                } else {
                    setIsOrderNotFound(true);  // Set state to show "Not Found" image
                    setErrorMessage('Order not found');
                    setShowOrder(false); // Hide the input box if order is not found
                }
            })
            .catch(function (error) {
                setErrorMessage('Error fetching order. Please try again later.');
                setIsOrderNotFound(true);  // Set state to show "Not Found" image on error
                console.log("Error:", error);
            });
    };
    const createdAtDate = moment(createdAt);
    const estimatedDelivery = createdAtDate.add(5, 'days').format("DD-MM-YYYY");

    return (
        <div>
            <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
            <div style={{ marginTop: "2.5rem" }}>
                <div >

                    {/* <div>
                        <div style={{ display: "flex", justifyContent: "space-around", padding: "1px 18px" }}>
                            <div style={{ flex: 1 }}>    <b>Order reference# </b></div>
                            <div style={{ flex: 1 }}>
                                <input
                                    type="text"
                                    name="orderId"
                                    value={orderId}
                                    onChange={(e) => setOrderId(e.target.value)}
                                    style={{ border: "1px solid black", width: "100%", paddingLeft: "4px" }}
                                />
                            </div>
                        </div>
                        &nbsp;
                        <center>
                            {orderId ? (
                                <Button
                                    variant="default"
                                    onClick={getOrderById}
                                    style={{
                                        color: "white",
                                        background: "#31197C",
                                        width: "auto",
                                        marginTop: "5px",
                                        height: "2rem",
                                        padding: "2px 10px 3px 10px"
                                    }}
                                >
                                    Submit
                                </Button>
                            ) : (
                                <Button
                                    variant="default"
                                    disabled={true}
                                    style={{
                                        color: "white",
                                        background: "#31197C",
                                        width: "auto",
                                        marginTop: "5px",
                                        height: "2rem",
                                        padding: "2px 10px 3px 10px"
                                    }}
                                >
                                    Submit
                                </Button>
                            )}
                        </center>
                    </div> */}
                    {/* {isOrderNotFound ?
                        <div style={{ textAlign: 'center', marginTop: '10vh', fontFamily: "Noto Sans" }}>
                            <center>
                                <img src={notfound} alt="Order Not Found" style={{ maxWidth: '100%', height: 'auto' }} />
                                <h3><b>Order not found</b></h3>
                                <h5><b>Check entered order number</b></h5>
                            </center>
                        </div> : ""} */}
                    {/* {!showOrder && !isOrderNotFound ? */}
                    <div className="your-jitbits"><Link to={{ pathname: `/vieworder/${orderId}` }} className="mr-1 link"><img alt="" src={back} style={{ width: "30px" }} /></Link>Tracking Details</div>
                    <div className="mt-1 tracking-container">
                        <div className="mt-2 header">
                            <span>
                                Estimated Delivery<br />
                                <span className="est-date">{createdAt ? estimatedDelivery : ""}</span>
                            </span>
                            <span>
                                Order ID<br />
                                <span className="est-date">{orderId}</span>
                            </span>
                        </div>
                        <div className="t-container">
                            <div className="timeline-block timeline-block-right">
                                <div className="marker"></div>
                                <div className="timeline-content">
                                    <div className="mt-1">Order Placed</div>
                                    <span className="timeline-text">
                                        {moment(createdAt).format("DD-MM-YYYY")}
                                    </span>
                                </div>
                            </div>
                            <div className="timeline-block timeline-block-left">
                                <div
                                    className={orderStatus === "Packed" || orderStatus === "Dispatched" || orderStatus === "Delivered"
                                        ? "marker"
                                        : "marker-empty"
                                    }
                                ></div>
                                <div className="timeline-content">
                                    <div className="mt-1">Packed</div>
                                    <span className="timeline-text">
                                        {orderStatus === "Packed" || orderStatus === "Dispatched" || orderStatus === "Delivered"
                                            ? moment(packedOnDate).format("DD-MM-YYYY")
                                            : ""}
                                    </span>
                                </div>
                            </div>
                            <div className="timeline-block timeline-block-right">
                                <div
                                    className={orderStatus === "Dispatched" || orderStatus === "Delivered"
                                        ? "marker"
                                        : "marker-empty"
                                    }
                                ></div>
                                <div className="timeline-content">
                                    <div className="mt-1">Dispatched</div>
                                    <span className="timeline-text">
                                        {orderStatus === "Dispatched" || orderStatus === "Delivered" ?
                                            moment(dispatchedDate).format("DD-MM-YYYY")
                                            : ""}<br />
                                        {orderStatus === "Dispatched" || orderStatus === "Delivered" ?
                                            <span>
                                                Tracking no<br />{trackingNumber}
                                            </span> : ""}
                                    </span>
                                </div>
                            </div>

                            <div className="timeline-block timeline-block-left">
                                <div
                                    className={orderStatus === "Delivered" ? "marker" : "marker-empty"}
                                ></div>
                                <div className="timeline-content">
                                    <div className="mt-1">Delivered</div>
                                    <span className="timeline-text">
                                        {deliveryDate ? moment(deliveryDate).format("DD-MM-YYYY") : ""}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* : ""} */}
                </div>
            </div>
            <BottomMenu />
        </div>
    );
}

export default TrackingLink;