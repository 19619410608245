import React, { useState, useEffect, useRef } from "react";
import './Interests.css';
import './DetailedArticle.css';
import { useHistory, Link } from 'react-router-dom';
import BottomMenu from "../BottomMenu/BottomMenu";
import { AiOutlineShareAlt } from "react-icons/ai";
import { Redirect } from 'react-router-dom';
import moment from "moment-timezone";
import polls from '../images/polls-new.svg';
import tshirt from '../images/tshirt.jpeg';
import tshirtback from '../images/tshirtback.jpeg';
import coin from '../images/sceem-coin.png';
import axios from 'axios';
import { config } from '../Config/Config';
import { RiAlertFill, RiAlertLine } from "react-icons/ri";
import angleright from '../images/angle-right-svgrepo-com.svg';
import Report from "./Report"
import { OverlayTrigger, Tooltip, Carousel, Row, Col, Button, Card, Popover, Form, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";
import flash from '../images/flash-on-svgrepo-com.svg';
import SimpleCrypto from "simple-crypto-js";


const key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY;
const simpleCrypto = new SimpleCrypto(key);

export default function DetailedArticle(props) {
    const history = useHistory();
    const [hasReachedThreshold, setHasReachedThreshold] = useState(false);
    const hasCalledUpdateRef = useRef(false);
    const [bookmark, setBookmark] = useState(false);
    const [like, setLike] = useState(false);
    const [share, setShare] = useState(false);
    const [postId, setPostId] = useState(props.match.params.id);
    const [token, setToken] = useState(localStorage.getItem('junkStore'));
    const [articleData, setArticleData] = useState([]);
    const [topicName, setTopicName] = useState("");
    const [authorUuid, setAuthorUuid] = useState("");
    const [authorName, setAuthorName] = useState("");
    const [authorImage, setAuthorImage] = useState("");
    const [description, setDescription] = useState("");
    const [typeOfReport, setTypeOfReport] = useState("");
    const [userViews, setUserViews] = useState("");
    const [show, setShow] = useState(false);
    const [campaign, setCampaign] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [reportFlag, setReportFlag] = useState("");
    const [follow, setFollow] = useState(false);
    const [postCount, setPostCount] = useState("");
    const [isFirstTime, setIsFirstTime] = useState(false);
    const [scrolled, setScrolled] = useState(0);
    const [scroll, setScroll] = useState(0);
    const [submitOption, setSubmitOption] = useState(false);
    const [options, setOptions] = useState([]);
    const [postOptions, setPostOptions] = useState([]);
    const [question, setQuestion] = useState("");
    const [userAnswer, setUserAnswer] = useState(null);
    const [navWidth, setNavWidth] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const [pollQuestion, setPollQuestion] = useState('');
    const [displayPopup, setDisplayPopup] = useState(false);
    const [appInstalled, setAppInstalled] = useState(true);
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [authenticated, setAuthenticated] = useState(false);
    const [showQuestionPopup, setShowQuestionPopup] = useState(false);
    const [userPosts, setUserPosts] = useState([]);
    const [newArticle, setNewArticle] = useState(false);
    const [showPoints, setShowPoints] = useState(false);
    const [hasCalledUpdate, setHasCalledUpdate] = useState(false);
    const [newId, setNewId] = useState();
    const [newTitle, setNewTitle] = useState();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    const toggleMenu = () => {
        setMenuOpen(prevState => !prevState);
    };
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowQuestionPopup(!showQuestionPopup)
        }, 1000);

        return () => clearTimeout(timer);
    }, []);
    // const getUserPosts = () => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": "Bearer " + token,
    //         "applicationType": "mobile"
    //     }
    //     var localPosts = localStorage.getItem('posts')
    //     var postData = JSON.parse(localPosts)
    //     const exp = new Date()
    //     if ((localPosts === null) || (exp.getTime() > postData.expiry)) {
    //         axios.get(config.userUrl + 'user/getPosts', { headers: headers })
    //             .then(function (response) {
    //                 if (response.data.status === true) {
    //                    // setUserPosts(response.data.posts)
    //                     var item = {
    //                         "latestPosts": JSON.stringify(response.data.posts),
    //                         "expiry": exp.getTime() + 60 * 60 * 100
    //                     }
    //                     localStorage.setItem("posts", JSON.stringify(item))
    //                 } else {
    //                     console.log(response.data)
    //                 }
    //             })
    //             .catch(function (error) {
    //                 console.log("Error", error);
    //             });
    //     } else {
    //         setUserPosts(JSON.parse(postData.latestPosts))
    //     }
    // };
    const getUserPosts = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getLatestPosts', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    setUserPosts(response.data.posts);
                }
            })
            .catch(function (error) {
                console.log("error", error);
            })
    };
    const closePopup = () => {
        setShowQuestionPopup(!showQuestionPopup)
    }
    // useEffect(() => {
    //     const openDeepLink = () => {
    //         if (!authenticated) {
    //             console.log("login");
    //             window.location.href = '/';
    //         } else {
    //             console.log("isUserLoggedIn");
    //             postLatestNews();
    //             getPostQuestionResultById();
    //         }
    //     };

    //     openDeepLink();
    //     window.addEventListener("scroll", onScroll);

    //     return () => {
    //         window.removeEventListener("scroll", onScroll);
    //     };
    // }, []);
    useEffect(() => {
        if (token) {
            try {
                setToken(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
                setAuthenticated(true);
                postLatestNews();
                getUserPosts()
                getPostQuestionResultById();
                window.addEventListener("scroll", onScroll);
                // console.error('token');
            } catch (error) {
                // If decryption fails or validation is unsuccessful, treat it as unauthenticated
                // console.error('Token decryption or validation failed:', error);
                setAuthenticated(false);
            }
        } else {
            // If token is not present, treat it as unauthenticated
            setAuthenticated(false);
            // console.error('else no token', authenticated);
            window.location.replace('/login')
        }
    }, [token]);
    // useEffect(() => {
    //     // Redirect to login screen if not authenticated
    //     if (!authenticated) {
    //       console.log('Redirecting to login');
    //       window.location.replace('/')
    //     }
    //   }, []);


    const onScroll = () => {
        const Scrolled = document.documentElement.scrollTop;
        const MaxHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const ScrollPercent = (Scrolled / (MaxHeight - 1750)) * 100;
        setScroll(ScrollPercent);
        console.log("scroll", ScrollPercent);
        // if (ScrollPercent === 99 ) {
        //     updateUserViewsByPostId();
        //     // hasCalledUpdateRef.current = true;
        // }
        if (ScrollPercent >= 99 && !hasReachedThreshold) {
            setHasReachedThreshold(true);
        }
    };


    useEffect(() => {
        if (hasReachedThreshold && !hasCalledUpdateRef.current) {
            updateUserViewsByPostId();
            hasCalledUpdateRef.current = true; // Prevent multiple calls
        }
    }, [hasReachedThreshold]);

    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);
    const shareArticle = (title) => {
        if (navigator.share) {
            navigator.share({
                url: `https://www.sceem.org/${title.replace(/ /g, "_")}/${postId}`,
            }).then(() => {
                //console.log('Thanks for sharing!', title);
            }).catch(err => {
                console.log("Error while using Web share API:", err);
                //  console.log(err);
            });
        } else {
            console.log("Sorry! Your browser does not support Web Share API");
        }
    };

    const postLatestNews = () => {
        const headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        };

        axios.get(config.userUrl + `user/getPostById/${props.match.params.id}`, { headers: headers })
            .then(response => {
                //  console.log(response);
                if (response.data.status === true) {
                    //   console.log("success!!");
                    setArticleData(response.data.post);
                    setBookmark(response.data.bookmarkedPostFlag);
                    setReportFlag(response.data.reportFlag);
                    setLike(response.data.likedPostFlag);
                    setTopicName(response.data.topicDetails.topicName);
                    setFollow(response.data.follow);
                    setPostCount(response.data.postCount);
                    setAuthorUuid(response.data.authorDetails.uuid);
                    setAuthorName(response.data.authorDetails.name);
                    setDescription(response.data.authorDetails.description);
                    setAuthorImage(response.data.authorDetails.profilePicture);
                    setUserViews(response.data.userViews);
                    setQuestion(response.data.post[0]?.question);
                    setOptions(response.data.postQuestionData);
                    setIsLoading(false);
                    setCampaign(response.data?.campaigns)
                } else {
                    console.log(response.data);
                }
            })
            .catch(error => {
                console.log("Error");
            });
    };
    const updateUserViewsByPostId = () => {
        const headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        };
        const payload = {
            postId: parseInt(postId)
        };

        axios.post(config.userUrl + "user/updateUserViewsByPostId", payload, { headers })
            .then(response => {
                if (response.data.status === true) {
                    setShowPoints(true);
                } else {
                    console.error("API Response:", response.data);
                }
            })
            .catch(error => {
                console.error("Error:", error.response ? error.response.data : error.message);
            });
    };

    const postLikes = () => {
        setLike(!like);

        const headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        };

        const payload = {
            postId: postId,
            likePost: !like,
            userIdPostBelongTo: articleData[0].userId
        };

        axios.post(config.userUrl + 'user/likePostToggle', payload, { headers: headers })
            .then(response => {
                //console.log("Likes", payload, response.data);
            })
            .catch(error => {
                console.log(error, payload);
            });
    };

    const followAuthor = () => {
        setFollow(!follow);
    };

    const postBookmark = () => {
        setBookmark(!bookmark);

        const headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        };

        const payload = {
            postId: postId,
            bookmarkPost: !bookmark
        };

        axios.post(config.userUrl + 'user/bookmarkPostToggle', payload, { headers: headers })
            .then(response => {
                //  console.log("bookmark", payload, response.data);
                if (response.data.status === true) {
                    //  console.log("success!!");
                }
            })
            .catch(error => {
                console.log(error, payload);
            });
    };
    const parseMarkdown = (markdownText) => {
        var newMarkdownText = markdownText
            .replace(/^#(.*$)/gim, `<h5 class="heading-color"><b>$1</b></h5>`)
            .replace(/^>(.*$)/gim, '<li>$1</li>')
            .replace(/\_(.+?)\_/gim, '<u>$1</u>')
            .replace(/\%(.+?)\%/gim, '<i>$1</i>')
            .replace(/\*(.+?)\*/gim, '<b>$1</b>')
            .replace(/\n*$/gim, '<p />');
        return newMarkdownText;
    };

    // Event handler for input changes
    const onChange = (e) => {
        setQuestion({ ...question, [e.target.name]: e.target.value });
    };
    const onChangeReport = (e) => {
        setTypeOfReport(e.target.value);
        console.log("val", e.target.value)
    };
    // Create Report function
    const createReport = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        };

        let payload = {
            "articleId": postId,
            "typeOfReport": parseInt(typeOfReport)
        };


        axios.post(config.userUrl + "user/saveArticleReport", payload, { headers: headers })
            .then((response) => {
                //console.log(response);
                if (response.data.status === true) {
                    //  console.log("Report created successfully");
                    //  console.log("payload", payload);
                    //   console.log(response.data);
                    document.getElementById("test").style.display = "none";
                    setShow(false);
                    postLatestNews();
                } else {
                    console.log("Failed to create Report");
                }
            })
            .catch(function (error) {
                console.log("Failed to add report", payload, error);
            });
    };

    // Follow Authors function
    const followAuthors = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        };

        let payload = {
            "authorIds": [authorUuid],
            "isFirstTime": isFirstTime
        };

        axios.post(config.userUrl + 'user/followAuthors', payload, { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status === true) {
                    // console.log("success", payload, response.data);
                    localStorage.removeItem("getUserFollowedAuthors");
                    postLatestNews();
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    // Unfollow Authors function
    const unfollowAuthors = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        };

        let payload = {
            "authorIds": [authorUuid],
            "isFirstTime": isFirstTime
        };

        axios.post(config.userUrl + 'user/unfollowAuthors', payload, { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status === true) {
                    //console.log("success", payload, response);
                    localStorage.removeItem("getUserFollowedAuthors");
                    postLatestNews();
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    // Show Popup function
    const showPopup = () => {
        setShow(!show);
    };

    // Hide Popover function
    const hidePopover = () => {
        document.getElementById("test").style.display = "none";
        setShow(false);
    };

    // Open Sidebar function
    const openSideBar = () => {
        setNavWidth('200px');
    };

    // Close Sidebar function
    const closeSideBar = () => {
        setNavWidth(0);
    };

    // Close Menu function
    const closeMenu = (e) => {
        if (e.target.tagName === "div") {
            document.getElementById("myNav").style.width = "0";
        }
    };

    // Check Answer function
    const checkAnswer = (answer) => {
        setUserAnswer(answer);
        setDisabled(false);
    };

    // Submit Post Question function
    const submitPostQuestion = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        };

        let payload = {
            "postId": parseInt(postId),
            "postQuestionOptionId": parseInt(userAnswer)
        };

        //console.log("payload", payload);

        axios.post(config.userUrl + 'user/submitPostQuestion', payload, { headers: headers })
            .then(function (response) {
                //   console.log(response);
                if (response.data.status === true) {
                    setSubmitOption(true);
                    getPostQuestionResultById();
                    setShowQuestionPopup(!showQuestionPopup)
                    // console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };

    // Get Post Question Result By Id function
    const getPostQuestionResultById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        };

        axios.get(config.userUrl + `user/getPostQuestionResultById/${postId}`, { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status === true) {
                    /// console.log("success");
                    const result = response.data.postQuestionResult;
                    if (result.length === 0) {
                        setSubmitOption(false);
                    } else {
                        setPollQuestion(result.question);
                        setPostOptions(result.options);
                        setSubmitOption(true);
                    }
                } else {
                    console.log(response.data);
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    const redirectToLatestArticle = (id, title) => {
        setNewArticle(!newArticle)
        setNewId(id)
        setNewTitle(title)

        // window.history.push(`/article/${title.replace(/ /g, "_")}/${id}`)
    }
    useEffect(() => {
        if (newArticle) {
            history.push(`/article/${newTitle.replace(/ /g, "_")}/${newId}`);
        }
    }, [newArticle, history]);
    // if (newArticle) {
    //     return <Redirect
    //         to={`/article/${newTitle.replace(/ /g, "_")}/${newId}`} />
    // }
    const percent = postOptions?.map((opt, idx) => (
        Math.round((opt?.percentage + Number.EPSILON) * 100 / 100)
    ))
    // console.log("percentakdhs", percent)

    if (isLoading) {
        // console.debug("renders: Loading...");
        return <div className="App"></div>;
    }
    const b = articleData.map((i) => {
        // console.log("info", (i.publishOn))
        var a = new Date(i.publishOn)
        var publish = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
        return publish;
    })
    if (displayPopup) {
        return <Report closePopup={showPopup} data={props} />
    }
    const contain = {
        background: "gray",
        height: "8px",
        position: "fixed",
        left: 0,
        zIndex: 1,
        width: "100%"
    };
    const progressBarStyle = {
        marginTop: "-0.2px",
        height: "8px",
        background: "#0dad61",
        width: `${scroll}%`
    }
    const scrollContent = {
        // overflowY: "scroll"
    }

    // if (!authenticated) {
    //     window.location.replace('/')
    //     // return <Redirect to="/" />;
    //   }
    // const renderContent = () => {
    //     if (authenticated) {
    const extractHeadings = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const headings = doc.querySelectorAll('h1');
        return Array.from(headings).map(h1 => h1.textContent);
    };
    return (
        <div >
            <div style={{ background: "#f5f5f5" }}>
                <div>
                    {articleData.map((data, id) => {
                        const headings = extractHeadings(data.content);
                        const modifiedContent = data.content.replace(/<h1>(.*?)<\/h1>/g, (match, p1) => {
                            const id = p1.replace(/\s+/g, '-').toLowerCase();
                            const className = !campaign ? 'article-h1' : 'article-h1';
                            return `<h1 class="${className}" id="${id}">${p1}</h1>`;
                        });
                        return (
                            <div style={scrollContent}>
                                <Helmet>
                                    <meta data-react-helmet="true" property="og:title" content={data.title} />
                                    <meta data-react-helmet="true" property="og:description"
                                        content={authorName} />
                                </Helmet>

                                <div>
                                    <div className="tag-title">
                                        <div style={{ position: "relative", top: "-2px" }}>
                                            <div style={contain}>
                                                <div style={progressBarStyle}>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Carousel interval={5000} indicators={true} className="d-block d-md-none">
                                        {campaign.map((campaign, i) => (
                                            <Carousel.Item key={i}>
                                                <Row className="d-flex justify-content-center" style={{ width: "100%", marginTop: "-0.5rem" }}>
                                                    <Col xs={12} md={4}>
                                                        <Link
                                                            to={`/advertisement/${campaign?.id}`}
                                                            className="link"
                                                            key={`ad-${campaign?.id}`}
                                                            style={{ fontFamily: 'Open Sans' }}
                                                        >
                                                            <Card className="adv-card">
                                                                <Row style={{ width: '100%' }} className="no-gutters">
                                                                    <Col md={3} xs={3}>
                                                                        <img
                                                                            src={
                                                                                campaign?.imageUrl === 'uploads/1649942289609advertisement.png'
                                                                                    ? config.userUrl + campaign?.imageUrl
                                                                                    : campaign?.imageUrl
                                                                            }
                                                                            className="adv-image-home-detail"
                                                                            alt="Advertisement"
                                                                            style={{ width: '100%' }}
                                                                        />
                                                                        {campaign?.hasViewed ? (
                                                                            <div style={{ position: "absolute", left: "4px", bottom: "-2px", color: 'green' }}><i class="fa fa-circle" aria-hidden="true"></i></div>
                                                                        ) : (
                                                                            <div style={{ position: "absolute", left: "4px", bottom: "-2px", color: 'red' }}><i class="fa fa-circle" aria-hidden="true"></i></div>

                                                                        )}
                                                                    </Col>
                                                                    <Col md={8} xs={8}>
                                                                        <div className="adv-detail-text">
                                                                            <img src={campaign?.bannerImg} className="bannerImg-d" alt="Banner" style={{ width: '100%' }} />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={1} xs={1}>
                                                                        <div className="arrow-adv">
                                                                            <img src={angleright} style={{ width: '20px' }} alt="Arrow" />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Card>
                                                        </Link>
                                                    </Col>
                                                </Row>
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                    <Row style={{ width: "100%" }} className="mt-2 no-gutters">
                                        <Col xs={2}>
                                            <img alt="" src={authorImage === null ? "../author5.png" : config.userUrl + authorImage} className="Author_icon" />
                                        </Col>
                                        <Col xs={8} >
                                            <div className="ml-3 mr-3" style={{ textAlign: "left", marginTop: "-4px", fontFamily: "Raleway, sans-serif" }}><span style={{ color: "black", fontSize: "18px", fontWeight: "700" }}>{authorName} </span><div style={{ fontSize: "10px" }}>{postCount == 1 ? <span>{postCount} article published.</span> : <div>{postCount} articles published.</div>}</div></div>
                                        </Col>
                                        <Col xs={2}>
                                            {follow ? <div><div style={{ fontSize: "10px", marginLeft: "-8px", fontFamily: "Raleway, sans-serif" }}>UnFollow</div><i class="fa fa-minus-circle fa-lg plus" onClick={unfollowAuthors} aria-hidden="true"></i></div> : <div><div style={{ fontSize: "10px", fontFamily: "Raleway, sans-serif" }} className="ml-1">Follow</div><i class="fa fa-plus-circle fa-lg plus" onClick={followAuthors} aria-hidden="true"></i></div>}
                                        </Col>
                                    </Row>
                                    <div className="ml-3" style={{ fontFamily: "Raleway, sans-serif" }}><i> {description}</i></div>
                                    <div className="tagline-title_">{data.title}</div>
                                    <Row style={{ width: "100%" }} className="no-gutters">
                                        <Col xs={10}>
                                            <div className="ml-3 mt-1 topic-art-d" style={{ fontFamily: "Raleway,sans-serif", color: "gray", fontSize: "12px" }} >
                                                <span style={{ fontSize: "12px", fontWeight: "500" }}>Topic:</span> {topicName.replace(/ *\([^]*\) */g, "")}</div>
                                            <div className="ml-3 topic-art-d" style={{ fontFamily: "Raleway,sans-serif", color: "gray", fontSize: "12px" }}> <span style={{ fontSize: "12px", fontWeight: "500" }}>Published On:</span> {moment(data.publishOn).format("DD MMM,YYYY")} <span className="ml-2"><i class="fa fa-eye" aria-hidden="true"></i>&nbsp;{userViews} Views</span></div>
                                        </Col>
                                        <Col xs={2}>
                                            <div className="menublue-container" style={{ marginTop: "-1.1rem" }}>
                                                <div className={`menublue-icon ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                                                    <center><i class="fa fa-bars" style={{ width: "20px" }} aria-hidden="true"></i></center>
                                                </div>
                                                <div className={`menublue-bar ${menuOpen ? 'open' : ''}`}>
                                                    <div className="menublue-items">
                                                        <div className="menublue-item">
                                                            <span onClick={postLikes} className="ml-2">
                                                                {like ?
                                                                    <i class="fa fa-thumbs-up"></i> :
                                                                    <i class="fa fa-thumbs-o-up"></i>
                                                                }</span>
                                                        </div>
                                                        <div className="menublue-item">
                                                            <span onClick={() => shareArticle(data.title)}>
                                                                <AiOutlineShareAlt size="20px" className="ic-top" />
                                                            </span>
                                                        </div>
                                                        <div className="menublue-item">
                                                            <span onClick={postBookmark}>
                                                                {bookmark ?
                                                                    <i className="fa fa-bookmark"></i> :
                                                                    <i className="fa fa-bookmark-o"></i>}
                                                            </span>
                                                        </div>
                                                        <div className="menublue-item">
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                trigger={reportFlag == 2 ?
                                                                    ""
                                                                    : (reportFlag == 3 ?
                                                                        ""
                                                                        : "click"
                                                                    )}
                                                                rootClose
                                                                overlay={(
                                                                    <Popover
                                                                        id="test">
                                                                        <Popover.Title as="h5">
                                                                            Report
                                                                        </Popover.Title>
                                                                        <Popover.Content>
                                                                            <Form.Check
                                                                                label="Unrelated"
                                                                                name="typeOfReport"
                                                                                type="radio"
                                                                                value={1}
                                                                                checked={typeOfReport == "1"}
                                                                                onChange={onChangeReport}
                                                                            />
                                                                            <Form.Check
                                                                                label="Plagiarism"
                                                                                name="typeOfReport"
                                                                                type="radio"
                                                                                value={2}
                                                                                checked={typeOfReport == "2"}
                                                                                onChange={onChangeReport}
                                                                            />
                                                                            <Form.Check
                                                                                label="Unformatted"
                                                                                name="typeOfReport"
                                                                                type="radio"
                                                                                value={3}
                                                                                checked={typeOfReport == "3"}
                                                                                onChange={onChangeReport}
                                                                            />
                                                                            {typeOfReport !== "" ?
                                                                                <center className="mt-2"><Button variant="default" className="_rolebtn" onClick={createReport}>Submit</Button>&nbsp;&nbsp;<Button variant="default" onClick={hidePopover} className="_rolebtn" >Cancel</Button></center> :
                                                                                <center className="mt-2"><Button variant="default" disabled="true" className="_rolebtn" onClick={createReport}>Submit</Button>&nbsp;&nbsp;<Button variant="default" onClick={hidePopover} className="_rolebtn" >Cancel</Button></center>}
                                                                        </Popover.Content>
                                                                    </Popover>
                                                                )}>
                                                                {reportFlag == 2 ?
                                                                    <OverlayTrigger placement="bottom" overlay={<Tooltip className="mt-3 tooltip">Pending</Tooltip>}>
                                                                        <RiAlertFill size="20px" style={{ color: "white" }} className="ic-top" />
                                                                    </OverlayTrigger>
                                                                    : (reportFlag == 3 ?
                                                                        <OverlayTrigger placement="bottom" overlay={<Tooltip className="mt-5 tooltip">Rejected</Tooltip>}>
                                                                            <RiAlertFill size="20px" style={{ color: "#FF942C" }} className="ic-top" />
                                                                        </OverlayTrigger>
                                                                        : <RiAlertLine size="20px" className="ic-top" />
                                                                    )}
                                                            </OverlayTrigger>
                                                        </div>
                                                        <div className="menublue-item">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* <div className="stickytable"> */}
                                    <div style={!campaign ? { position: "sticky", top: "6.6rem", zIndex: 1000 } : { position: "sticky", top: "0.5rem", zIndex: 1000 }}>
                                        <div className="mt-2 table-of-contents-container">
                                            <div className="table-content" style={{ fontFamily: "Raleway,sans-serif" }}>Table of Contents</div>
                                            <div>
                                                <div onClick={toggleDropdown} style={{ border: "none" }}>
                                                    {isDropdownOpen ? <i class="fa fa-angle-up angle-size" aria-hidden="true"></i> : <i class="fa fa-angle-down angle-size" aria-hidden="true"></i>} {/* Toggle arrow */}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div key={id}>
                                                {isDropdownOpen && (
                                                    <div className="dd-content">
                                                        <ol>
                                                            {headings.map((heading, index) => {
                                                                const id = heading.replace(/\s+/g, '-').toLowerCase(); // Create the same ID
                                                                return (
                                                                    <li key={index} className="mt-1 article-h1">
                                                                        <a
                                                                            href={`#${id}`}
                                                                            style={{ color: "black" }}
                                                                            onClick={(e) => {
                                                                                e.preventDefault(); // Prevent the default anchor behavior
                                                                                toggleDropdown();
                                                                                const element = document.getElementById(id);
                                                                                if (element) {
                                                                                    element.scrollIntoView({ behavior: 'smooth' });
                                                                                }
                                                                            }}
                                                                        >
                                                                            {heading}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            })}
                                                            {data.question && (
                                                                <li className="mt-1">
                                                                    <a
                                                                        href="#article-poll"
                                                                        onClick={toggleDropdown}
                                                                        style={{ color: "black" }}
                                                                    >
                                                                        Article Poll
                                                                    </a>
                                                                </li>
                                                            )}
                                                        </ol>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ wordWrap: "break-word" }} className="mt-3 html-texT article-h1" dangerouslySetInnerHTML={{ __html: modifiedContent }} />
                                    <center
                                        style={{
                                            fontSize: "13px",
                                            marginTop: "-1px",
                                            fontWeight: 600,
                                            visibility: showPoints ? "visible" : "hidden",
                                            opacity: showPoints ? 1 : 0,
                                            transition: "opacity 0.3s ease-in-out",
                                            height: "auto",
                                        }}
                                    >
                                        You are awarded
                                        <strong style={{ fontSize: "18px", marginTop: "2px" }}> 3</strong>
                                        <img
                                            src={coin}
                                            style={{ marginLeft:"5px",width: "28px", marginTop: "-3px" }}
                                            alt="coin"
                                        />
                                    </center>
                                    <center><hr style={{ width: "70%", marginTop: "10px", marginBottom: "8px" }} /></center>
                                    {data.question == "" ? <div style={data.question ? {} : { display: "none" }}></div> :
                                        <div style={data.question ? {} : { display: "none" }}>
                                            {submitOption ?
                                                <div id="article-poll" className={!campaign ? 'no-art-d_' : 'art-d_'}>
                                                    <Col xs={12}>

                                                        <center><img src={polls} style={{ width: "20px" }} />&nbsp;<span className="art-poll-text-d">Article Poll</span><span style={{ marginLeft: "8px", fontSize: "16px", fontWeight: 600 }}>2 <img src={coin} style={{ width: "25px", marginTop: "-3px" }} /></span></center>
                                                        {/* <Card className="ml-2 mt-1 poll-result-card-post-d">
                                                                <div style={{ padding: "5px" }}>
                                                                    <center className="poll-question-r"><b>{question}</b></center>
                                                                    <div>
                                                                        {postOptions.map((opt, idx) => (
                                                                            <Progressbar uAns={opt.isAnswer} answer={userAnswer} id={opt.id} hValue={percent} option={opt.option} percentage={percent[idx]} />
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </Card> */}
                                                        <div className="poll-question-card-post-d">
                                                            <center className="question_post">{question}</center>
                                                        </div>
                                                        <Card className="mb-1 poll-result-card-post-d">
                                                            <div>
                                                                {postOptions.map((opt, idx) => (
                                                                    <Progressbar uAns={opt.isAnswer} answer={userAnswer} id={opt.id} hValue={percent} option={opt.option} percentage={percent[idx]} />
                                                                ))}
                                                            </div>
                                                            <center style={{ fontSize: "13px", fontFamily: "Noto Sans", marginTop: "-1px", fontWeight: 600 }}>You are awarded<strong style={{ fontSize: "18px", marginTop: "2px" }}> 2</strong> <img src={coin} style={{ width: "28px", marginTop: "-3px" }} /></center>
                                                        </Card>
                                                    </Col>
                                                </div>
                                                : <>
                                                    <Modal show={showQuestionPopup} size="lg"
                                                        aria-labelledby="contained-modal-title-vcenter"
                                                        centered>
                                                        <Modal.Body >
                                                            <center><img src={polls} style={{ width: "20px" }} />&nbsp;<span className="art-poll-text-d">Article Poll</span><span style={{ marginLeft: "8px", fontSize: "18px", fontWeight: 600 }}>2 <img src={coin} style={{ width: "25px", marginTop: "-3px" }} /></span></center>
                                                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "-20px" }}> <div></div><i onClick={closePopup} className="cursor-pointer fa fa-times fa-lg" aria-hidden="true"></i></div>
                                                            <br />
                                                            <div className="poll-question-card-post-d">
                                                                <center className="question_post">{question}</center>
                                                            </div>
                                                            <Card className="poll-answer-card-post-d">
                                                                {options.map((option, idx) => {
                                                                    return (
                                                                        <div onClick={() => checkAnswer(option.id)} className={`polloptions-card-post-d ${userAnswer === option.id ? "selectedd" : null}`}><div className="poll-text-d">{option.option}</div></div>
                                                                    )
                                                                })}

                                                                {userAnswer === null ? <center><Button variant="default" disabled={true} className="mt-4 mb-1 q-submit-btn" >&nbsp;Submit &nbsp;</Button></center>
                                                                    :
                                                                    <center><Button variant="default" className="mt-4 mb-1 q-submit-btn" onClick={submitPostQuestion}>&nbsp;Submit &nbsp;</Button></center>}

                                                            </Card>
                                                        </Modal.Body>
                                                    </Modal>

                                                    <div id="article-poll" className={!campaign ? 'no-art-d_' : 'art-d_'}>
                                                        <Col xs={12}>
                                                            <center><img src={polls} style={{ width: "20px" }} />&nbsp;<span className="art-poll-text-d">Article Poll</span><span style={{ marginLeft: "4px", fontSize: "18px", fontWeight: 600 }}>2 <img src={coin} style={{ width: "25px", marginTop: "-3px" }} /></span></center>
                                                            {/* <Card className="mt-1 ml-2 poll-answer-card-post-d">
                                                                        <div className="poll-question"><b>{question}</b></div>
                                                                        {options?.map((option, idx) => {
                                                                            return (
                                                                                <div onClick={() => checkAnswer(option.id)} className={`polloptions-card-post ${userAnswer === option.id ? "selected" : null}`}><div className="poll-text">{option.option}</div></div>
                                                                            )
                                                                        })}
                                                                    </Card> */}
                                                            <div className="poll-question-card-post-d">
                                                                <center className="question_post">{question}</center>
                                                            </div>
                                                            <Card className="poll-answer-card-post-d">
                                                                {options.map((option, idx) => {
                                                                    return (
                                                                        <div onClick={() => checkAnswer(option.id)} className={`polloptions-card-post-d ${userAnswer === option.id ? "selectedd" : null}`}><div className="poll-text-d">{option.option}</div></div>
                                                                    )
                                                                })}

                                                                {userAnswer === null ? <center><Button variant="default" disabled={true} className="mt-4 mb-1 q-submit-btn" >&nbsp;Submit &nbsp;</Button></center>
                                                                    :
                                                                    <center><Button variant="default" className="mt-4 mb-1 q-submit-btn" onClick={submitPostQuestion}>&nbsp;Submit &nbsp;</Button></center>}

                                                            </Card>

                                                        </Col>
                                                    </div>

                                                </>

                                            }
                                        </div>}
                                </div>

                            </div>
                        )
                    })}

                    <div className="mt-3 latest-dd merchandise">
                        <center className="table-content">Merchandise
                        </center>
                    </div>
                    {articleData.map((data, id) => (
                        <div className="ts-left-right-d">
                            <center><img alt="" src={config.userUrl + data.image} className="desc-Image" /></center>
                            <Row style={{ width: "100%" }} className="mt-2 no-gutters">
                                <Col md={6} xs={6}>
                                    <center>
                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                            <img src={tshirt} className="tshirt-img" alt="T-shirt" />
                                            <img
                                                src={config.userUrl + data.image}
                                                style={{
                                                    position: 'absolute',
                                                    top: '39%',
                                                    left: '40%',
                                                    width: '25%',
                                                    height: 'auto',
                                                }}
                                                alt="Overlay"
                                            />
                                        </div>
                                    </center>
                                </Col>
                                <Col md={6} xs={6}>
                                    <center>
                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                            <img src={tshirtback} className="tshirt-img1" alt="T-shirt" />
                                            <img
                                                src={''}
                                                style={{
                                                    display: "none",
                                                    position: 'absolute',
                                                    top: '35%',
                                                    left: '32%',
                                                    width: '32%',
                                                    height: 'auto',
                                                }}
                                                alt="Overlay"
                                            />
                                        </div>
                                    </center>
                                </Col>
                            </Row>

                            <div className="mt-3 tshirt-left"><span className="tshirt">T-Shirt Design &nbsp;</span><span className="tshirt-sm"> ({data.title})</span></div>
                            <div className="mt-2 tshirt tshirt-left">Rs 599</div>
                            <div><Link to={`/ordertshirt/${postId}`}><Button variant="default" className="mt-4 btn btn-block block-d" style={{ background: "#31197C", color: "white", fontFamily: "Noto Sans" }}>Buy Now</Button></Link></div>

                        </div>
                    ))}
                    <div style={{ marginTop: "0.5rem" }}>
                        <div className="mt-3 latest-dd">
                            <center className="table-content">Latest Articles</center>
                        </div>
                        <Row style={{ width: "100%" }} className="no-gutters">
                            <Col xs={12}>
                                {userPosts?.length !== 0 ? (
                                    // latestPosts.slice(0, 3)
                                    //     .filter(data => postId !== data.id)
                                    userPosts.filter(data => parseInt(props.match.params.id) !== data.id) // Filter out items where id matches
                                        .slice(0, userPosts.map(post => post.id).includes(parseInt(props.match.params.id)) ? 5 : 5) // Slice based on condition
                                        .map((data, index) => (
                                            <Card
                                                className="mb-4 latest-art-card-d"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => redirectToLatestArticle(data.id, data.title)}
                                            >
                                                <Row style={{ width: "100%" }} className="no-gutters">
                                                    <Col md={4} xs={4}>
                                                        <div>
                                                            <center>
                                                                <img
                                                                    src={config.userUrl + data.image}
                                                                    alt={data.title}
                                                                    className="article-sm-img"
                                                                />
                                                            </center>
                                                        </div>
                                                    </Col>
                                                    <Col md={8} xs={8}>
                                                        <div className="ml-2">
                                                            {data.title !== null && data.title.length > 100 ? (
                                                                <div className="favbits-title-latest-d">{data.title.substring(0, 100)}...</div>
                                                            ) : (
                                                                <div className="favbits-title-latest-d ">{data.title}</div>
                                                            )}
                                                            <div className="mt-2 topic-d">
                                                                Topic: {data.topicName.replace(/ *\([^]*\) */g, "")}
                                                            </div>
                                                            <div style={{ padding: "2px", display: "flex", flexDirection: "column" }}>
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>

                                                                    <div style={{ display: "flex", alignItems: "center", fontFamily: "Noto Sans" }}>
                                                                        <img
                                                                            alt=""
                                                                            src={config.userUrl + data.authorProfilePicture}
                                                                            className="favbits-author-d"
                                                                        />
                                                                        <span style={{ fontSize: "12px" }}>&nbsp;{data.authorName}</span>
                                                                    </div>
                                                                </div>

                                                                <div style={{ display: "flex", cursor: "pointer", color: "#b587f4", fontWeight: 600 }}
                                                                    className="mt-2 favbits-title-latest-d ">Read more &nbsp; <i class="fa fa-angle-right" aria-hidden="true" style={{ marginTop: "4.5px", fontWeight: 600 }}></i></div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        ))
                                ) : (
                                    <center></center>
                                )}
                            </Col>
                        </Row>

                    </div>


                </div>
            </div>
            <div className="mb-bottom"></div>
            <BottomMenu />
        </div >
    )
    //    }
    //     else {
    //         return <Redirect to="/" />;
    //     }
    // };
    // return (
    //     <div>
    //         {renderContent()}
    //     </div>
    //  );
}
const Progressbar = (props) => {
    const { percentage, option, uAns, selected, hValue, id, answer } = props;
    const backgroundColor = `linear-gradient(to right, #EEEBFF 0%, #EEEBFF ${percentage}%, white ${percentage}%, white 99%)`
    const containerStyles = {
        background: backgroundColor,
    }

    const isSelected = {
        border: "2px solid #DB6C00",
        borderRadius: 10,
        background: backgroundColor,
    }
    const isHighest = {
        border: "2px solid #31197C",
        borderRadius: 10,
        background: backgroundColor
    }
    const labelStyles = {
        color: 'black',
        borderColor: "#362B79",
        fontWeight: 500,
        fontFamily: 'Raleway, sans-serif',
        fontSize: "14px",
        position: "relative",
        height: '6.5vh',
        marginTop: '1px',
        borderRadius: 8,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }
    const optionLabels = {
        fontWeight: 500,
        fontFamily: 'Raleway, sans-serif',
        margin: 'auto',
        height: '6.7vh',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }
    const largestElement = (arr) => {
        return Math.max(...arr);
    }
    return (
        <div style={id === answer ? isSelected : largestElement(hValue) === percentage ? isHighest : containerStyles} className="container_class-d">
            <div style={labelStyles}>
                <div style={optionLabels} className="ml-1">{option}</div>

            </div>
            <div style={{ fontWeight: "600", fontFamily: 'Raleway, sans-serif', marginRight: "5px" }}>{`${percentage}%`}</div>
        </div>

    );
};