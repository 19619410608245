import React, { Component } from "react";
import { Button, Form, Col, Card, Row, Image } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import back from '../images/backarrow.png';
import axios from 'axios';
import moment from "moment-timezone";
import fileupload from '../images/file-upload-svgrepo-com.svg';
import { config } from '../Config/Config';
import { enqueueSnackbar } from "notistack";
import activecircle from '../images/active-cir.svg';
import pendingcircle from '../images/pending-cir.svg';
import rejectedcircle from '../images/rejected-cir.svg';
import needinfocircle from '../images/needinfo-cir.svg';
import publishedcircle from '../images/published-cir.svg';
import "react-datepicker/dist/react-datepicker.css";
import SimpleCrypto from "simple-crypto-js";
import BottomMenu from "../BottomMenu/BottomMenu";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class DetailedAdv extends Component {
    constructor(props) {
        super(props);
        this.imgRef = React.createRef();
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            typeOfAdvertisement: "",
            description: "",
            timingType: "",
            paymentType: "",
            advType: "",
            startDate: "",
            campaignName: "",
            clickAmount: "",
            viewAmount: "",
            reason: "",
            name: "",
            designation: "",
            campaignUrl: "",
            image: "",
            bannerImg: "",
            advTypes: [],
            endDate: "",
            phoneNumber: "",
            clicks: "",
            views: "",
            email: "",
            amount:'',
            status: '',
            home: false,
        };
    }
    componentDidMount() {
        this.getAllAdvertismentTypes();
        this.getCampaignById();
    }
    getAllAdvertismentTypes = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAllAdvertismentTypes', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status == true) {
                    self.setState({ advTypes: response.data.advTypes })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    getCampaignById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + `user/getUserAdvById/${this.props.match.params.id}`, { headers: headers })
            .then(function (response) {
                //   console.log(response);
                if (response.data.status === true) {
                    //  console.log("success!!");
                    self.setState({
                        campaignName: response.data.campaign.campaignName,
                        name: response.data.campaign.advertiserName,
                        phoneNumber: response.data.campaign.advertiserNumber,
                        email: response.data.campaign.advertiserEmail,
                        paymentType: response.data.campaign.advertiserPaymentType,
                        campaignUrl: response.data.campaign.campaignUrl,
                        description: response.data.campaign.description,
                        designation: response.data.campaign.designation,
                        startDate: response.data.campaign.startDate,
                        endDate: response.data.campaign.endDate,
                        image: response.data.campaign.imageUrl,
                        typeOfAdvertisement: response.data.campaign.type,
                        advType: response.data.campaign.advType,
                        bannerImg: response.data.campaign.bannerImg,
                        clicks: response.data.campaign.clicks,
                        views: response.data.campaign.views,
                        clickAmount: response.data.campaign.clickAmount,
                        viewAmount: response.data.campaign.viewAmount,
                        amount: response.data.campaign.amount,
                        timingType: response.data.campaign.timingType,
                        reason: response.data.campaign.reason,
                        status: response.data.campaign.status
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };


    render() {
        const { home, image, imgRef, bannerImg, status } = this.state
        if (home) {
            return <Redirect to="/home" />
        }
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo mt-2" /></div>
                <Form style={{ marginTop: "4.4rem" }}>
                    <div className="your-fav" style={{ position: "fixed", top: "2.2vh" }}> <div className="screen-topgap ml-2">Advertisement Details
                        <span>
                            {status == 2 ? <img src={pendingcircle} className="adv-circles-opp" />
                                : (status == 1 ? <img src={activecircle} className="adv-circles-opp" />
                                    : (status == 3 ? <img src={needinfocircle} className="adv-circles-opp" />
                                        : (status == 4 ? <img src={publishedcircle} className="adv-circles-opp" />
                                            : <img src={rejectedcircle} className="adv-circles-opp" />)))}</span></div></div>
                    <div className="card-opp-top"></div>
                    <div style={{ marginLeft: "5%", marginRight: "5%", width: "90%" }} >
                        <Card className="createopportunity-card"> <div style={{ marginLeft: "3%", marginRight: "3%" }} >
                            <Row style={{ width: "100%", marginTop: "-1px" }} className="mb-2 no-gutters">
                                <Col xs={9}>
                                    <div style={{ marginTop: "4px", height: "4.4rem", width: "100%", textAlign: "center", objectFit: "contain", border: "1px solid gray", borderRadius: "10px" }}>
                                        <label htmlFor="upload-button-banner">
                                            {bannerImg != "" ? (
                                                <img src={bannerImg} style={{ marginTop: "3px", marginBottom: "3px", width: "220px", height: "4rem" }} />
                                            ) : (
                                                <>
                                                    <div className="up-load" style={{ marginTop: "3px", fontSize: "12px", fontWeight: 600 }}>Product/Promotion Banner</div>
                                                    <img src={fileupload} style={{ marginTop: "1px", width: "35px" }} />
                                                </>
                                            )}
                                        </label>
                                        <input
                                            type="file"
                                            style={{ display: "none" }}
                                            id="upload-button-banner"
                                            name="bannerImage"
                                            ref={imgRef}
                                        />
                                    </div>
                                </Col>
                                <Col xs={3}>
                                    <div style={{ height: "70px", marginTop: "0.2rem", textAlign: "center", objectFit: "contain", border: "1px solid gray", borderRadius: "10px" }}>
                                        <label htmlFor="upload-button-logo">
                                            {image != "" ? (
                                                <img src={image} style={{ marginTop: "0.05rem", width: "67px", height: "68px" }} />
                                            ) : (
                                                <>
                                                    <div style={{ marginTop: "-1px", fontSize: "12px", fontWeight: 600 }}>Upload</div>
                                                    <img src={fileupload} style={{ width: "29px" }} />
                                                    <div style={{ marginTop: "-1px", fontSize: "12px", fontWeight: 600 }}>Logo</div>
                                                    {/* <i class="fa fa-upload fa-2x" style={{ cursor: "pointer" }} aria-hidden="true"></i> */}
                                                </>
                                            )}
                                        </label>
                                        <input
                                            type="file"
                                            style={{ display: "none" }}
                                            id="upload-button-logo"
                                            name="image"
                                            ref={imgRef}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        </Card>
                        <Card className="createopportunity-card"> <div style={{ marginLeft: "3%", marginRight: "3%" }} >
                            <div>
                                <input type="text"
                                    className="form-control"
                                    style={{ resize: "none", fontSize: "12px" }}
                                    value={this.state.campaignName}
                                    readOnly
                                    name="campaignName"
                                    placeholder="Campaign Name"
                                />
                                <input type="text"
                                    className="form-control"
                                    style={{ resize: "none", fontSize: "12px" }}
                                    value={this.state.campaignUrl}
                                    name="campaignUrl"
                                    readOnly
                                    placeholder="Campaign Url"
                                />
                            </div>

                            <textarea rows={5} type="text"
                                className="form-control"
                                style={{ resize: "none", fontSize: "12px" }}
                                value={this.state.description}
                                readOnly
                                name="description"
                                placeholder="Description"
                            />
                            {/* <label className="ml-1 mt-1 poll-date" style={{ fontSize: "14px" }}>Upload Banner Image<span className="red">*</span></label><br /> */}

                            <Row style={{ width: "100%", marginTop: "-3px" }} className="no-gutters">
                                <Col xs={6}>
                                    <input type="text"
                                        className="form-control"
                                        readOnly
                                        style={{ resize: "none", fontSize: "12px" }}
                                        value={this.state.typeOfAdvertisement}
                                        name="typeOfAdvertisement"
                                    />
                                </Col>
                                <Col xs={6}>
                                    <input type="text"
                                        name="advType"
                                        readOnly
                                        className="form-control form-size"
                                        value={this.state.advType}
                                    />
                                </Col>
                            </Row>
                            <Row style={{ width: "100%", marginTop: "-3px" }} className="no-gutters">
                                <Col xs={6}>
                                    <input type="text"
                                        name="paymentType"
                                        readOnly
                                        className="form-control form-size"
                                        value={this.state.paymentType}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <input type="text"
                                        name="timingType"
                                        readOnly
                                        className="form-control form-size"
                                        value={this.state.timingType}
                                    />
                                </Col>
                            </Row>
                            <Row style={{ width: "100%", marginTop: "-3px" }} className="no-gutters">
                                <Col xs={6}>
                                    <input
                                        name="startDate"
                                        readOnly
                                        className="form-control form-size"
                                        value={moment(this.state.startDate).format("DD-MM-YYYY")}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <input
                                        name="endDate"
                                        readOnly
                                        className="form-control form-size"
                                        value={moment(this.state.endDate).format("DD-MM-YYYY")}
                                    />
                                </Col>
                            </Row>
                            <Row style={{ width: "100%", marginTop: "-3px" }} className="no-gutters">
                                <Col xs={6}>
                                    {/* <input type="number"
                                        name="views"
                                        readOnly
                                        className="form-control form-size"
                                        value={this.state.views}
                                        placeholder="No.of Views"
                                        style={{ marginRight: '5px' }}
                                    /> */}
                                    <div style={{ marginTop: "-1px", display: 'flex', alignItems: 'center' }}>
                                        <span style={{ width: "60%", marginLeft: '2px', marginRight: '2px', fontSize: "10px" }}>Views in duration</span>
                                        <input
                                            type="number"
                                            name="views"
                                            className="form-control form-size"
                                            style={{ width: "40%", paddingLeft: "7px", paddingRight: "5px" }}
                                            value={this.state.views}
                                            disabled
                                        />
                                    </div>

                                </Col>
                                <Col xs={6}>
                                <div style={{ marginTop: "-1px", display: 'flex', alignItems: 'center' }}>
                                        <span style={{ width: "60%", marginLeft: '2px', marginRight: '2px', fontSize: "10px" }}>Clicks in duration</span>
                                        <input
                                            type="number"
                                            name="clicks"
                                            className="form-control form-size"
                                            style={{ width: "40%", paddingLeft: "7px", paddingRight: "5px" }}
                                            value={this.state.clicks}
                                            disabled
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <input type="text"
                                name="viewAmount"
                                className="form-control form-size mb-2"
                                value={`Rs.${this.state.amount}`}

                                // value={`Rs.${this.state.paymentType === "Free" ? '0' : (250 + (this.state.clicks > 0 ? parseInt(this.state.clicks) * 25 : 0))}`}

                                // value={`Rs.` + ((this.state.paymentType == "Free" ? 100 : this.state.views) * (this.state.paymentType === "Free" ? 3 : 12)) + "/mon"}
                                disabled={true} />
                            {/* <Row style={{ width: "100%", marginTop: "-3px" }} className="mb-2 no-gutters">
                                <Col xs={6}>
                                    <input type="text"
                                        name="viewAmount"
                                        className="form-control form-size"
                                        value={`Rs.` + this.state.viewAmount + "/mon"}
                                        disabled={true} />
                                </Col>  <Col xs={6}>
                                    <input type="text"
                                        name="clickAmount"
                                        className="form-control form-size"
                                        value={`Rs.` + this.state.clickAmount + "/click"}
                                        style={{ marginRight: '5px' }}
                                        disabled={true} />
                                </Col>
                            </Row> */}
                        </div>
                        </Card >
                        <Card className="createopportunity-card">
                            <div style={{ marginLeft: "3%", marginRight: "3%" }} >
                                <Row style={{ width: "100%" }} className="mb-2 no-gutters">
                                    <Col xs={12}>
                                        <textarea type="text"
                                            rows={3}
                                            className="form-control form-size"
                                            aria-describedby="text"
                                            style={{ resize: "none" }}
                                            value={this.state.reason}
                                            readOnly
                                            name="reason"
                                            placeholder="Reason"
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Card>

                        {/* <Card className="createopportunity-card">
                            <div style={{ marginLeft: "3%", marginRight: "3%" }} >
                                <Row style={{ width: "100%" }} className="no-gutters">
                                    <Col xs={6}>
                                        <input type="text"
                                            className="form-control form-size"
                                            aria-describedby="text"
                                            value={this.state.name}
                                            readOnly
                                            name="name"
                                            placeholder="Name"
                                        />
                                    </Col>
                                    <Col xs={6}>
                                        <input type="text"
                                            className="form-control form-size"
                                            aria-describedby="text"
                                            value={this.state.designation}
                                            readOnly
                                            name="designation"
                                            placeholder="Designation"
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ width: "100%", marginTop: "-3px" }} className="no-gutters mb-2">
                                    <Col xs={6}>
                                        <input type="email"
                                            className="form-control form-size"
                                            aria-describedby="text"
                                            value={this.state.email}
                                            readOnly
                                            name="email"
                                            placeholder="Email"
                                        />
                                    </Col>
                                    <Col xs={6}>
                                        <input type="number"
                                            className="form-control form-size"
                                            aria-describedby="text"
                                            value={this.state.phoneNumber}
                                            readOnly
                                            name="phoneNumber"
                                            placeholder="Contact person phone"
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Card> */}

                    </div >
                </Form >
                <BottomMenu />
            </div >
        );
    }
}
